import React from "react";
import "./EventRegistration.css";
// import { Carousel } from "react-bootstrap";

import contactImg from "../../../assets/contact.png";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

export default function EventRegistration() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="EventRegistration">
        {/* Header Section */}
        <header className="container-fluid overflow-hidden px-0 position-relative">
          <div className="row h-100 align-items-center gap-4 gap-md-0 mx-0 justify-content-end justify-content-md-between">
            <div className="d-none d-md-block col-md-1 "></div>
            <div className="col-12 col-md-5 order-1 order-md-0 px-3 px-md-5">
              <h1 className="text-primary fw-bold">
                Event Registration Management
              </h1>
              <p>
                The registration process has never been easier before. Using
                state-of-the-art tool Businesses can afford to focus their time
                on more important aspects of the business event.
              </p>
            </div>
            <div className="col-8 col-md-6 px-0 my-0 ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/header.png"
                className="img-fluid w-100"
                alt=""
              />
            </div>
          </div>
        </header>

        {/* <!-- Main Container --> */}

        <section className="main container-md my-5 py-0 py-md-5">
          {/* <!-- 1st --> */}
          <div className="row mx-0 my-5 gap-3 gap-md-0 ">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Everything+u+need.png"
                className="img-fluid row-img"
                alt="Event Registration and communication"
              />
            </div>
            <div className="col-12 col-md-5 d-flex flex-column  justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Everything You Need</h3>
              <p>
                Streamline the event planning process through automation. It is
                secure, scalable, production-ready & has got everything you
                need. Adjust settings like commission rates, default currency,
                timezone, language, and so much more from the convenience of
                your admin panel.
              </p>
            </div>
          </div>
          {/* <!-- 2nd --> */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Work+from+home.png"
                className="img-fluid row-img"
                alt="Custom Landing Page for a Great First Impression "
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">
                Host a Multi-Vendor Event Platform
              </h3>
              <p>
                Allow multiple organisers to publish events on your website
                while earning commissions on each paid booking.
              </p>
            </div>
          </div>
          {/* <!-- 3rd --> */}
          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Form.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">
                Integrated Ticket Scanner
              </h3>
              <p>
                Scan & validate customers’ tickets at the event entrance & check
                them in with the integrated Ticket Scanner app.
              </p>
              <h3 className="text-primary fw-bold">Publish Multiple Events </h3>
              <p>
                It is a one-stop solution to publish, promote, and make your
                event a success.
              </p>
            </div>
          </div>
          {/* <!-- 4th --> */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Auto-generated+PDF+Ticket+with+QrCode.png"
                className="img-fluid row-img"
                alt="Upload Documents"
              />
            </div>
            <div className="col-12 col-md-5 d-flex flex-column justify-content-center">
              <h3 className="text-primary fw-bold">
                Auto-generated PDF Ticket with QrCode
              </h3>
              <p>
                After checkout, customers get PDF tickets with unique QrCode on
                each.
              </p>
              <h3 className="text-primary fw-bold">
                Multiple Booking Configurations
              </h3>
              <p>
                Whether an organiser’s event is free, paid, or has a maximum
                ticket quantity, our various booking configurations have you
                covered.
              </p>
            </div>
          </div>
          {/* <!-- 5th --> */}
          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/organizer+earning.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Organiser Earnings</h3>
              <p>
                Organisers can manage & track their Event’s net revenue,
                earnings, and monthly payouts easily.
              </p>
              <h3 className="text-primary fw-bold">
                Organizer & Client Separate Taxation{" "}
              </h3>
              <p>
                Create multiple taxes that can be applied by organisers &
                clients separately.
              </p>
            </div>
          </div>
          {/* <!-- 6th --> */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Meeting+Scheduler.png"
                className="img-fluid row-img"
                alt="Meeting Scheduler"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center text-center">
              <h3 className="text-primary fw-bold">Event Tags</h3>
              <p>
                Create and link unlimited tags to events e.g Speakers, Sponsors,
                DJs, Hosts, Chief-guests, and more.{" "}
              </p>
            </div>
          </div>
          {/* <!-- 7th --> */}
          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Flexible+template+based+landing+page.png"
                className="img-fluid row-img"
                alt="Profile Search  (Match)"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">
                Responsive. Secure. Robust
              </h3>
              <p>
                Protecting your customers with state-of-the-art security and a
                clean and attractive user-interface regardless of screen size.
                Optimised for all devices in an increasingly mobile-friendly
                world.
              </p>
              <p>
                Our event ticket platform is 100% secure and production-ready to
                integrate with your event planning apps or websites. (Custom
                feature).
              </p>
            </div>
          </div>
          {/* <!-- 8th --> */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center text-md-end order-md-1">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Reserved+Seating+System.png"
                className="img-fluid row-img"
                alt="Post-Event Analytics "
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Reserved Seating System</h3>
              <p>
                Add any kind of seating arrangement e.g Stadiums, Townhalls,
                Cinema, Restaurants, Lounges, etc{" "}
              </p>
              <h3 className="text-primary fw-bold">Clone Event</h3>
              <p>
                Duplicate existing events to speed up creating a new event,
                rather than creating them from scratch.
              </p>
            </div>
          </div>
          {/* <!-- 10th --> */}
          <div className="row mx-0 my-5 py-0 py-md-5 gap-3 gap-md-0">
            <div className="col-10 col-md-4 text-center mx-auto">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/email.png"
                className="img-fluid"
                alt="Capture Leads"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">MailChimp Integration</h3>
              <p>
                Subscribe event attendees directly to MailChimp account with
                event tag, so that you can manage to send email to event
                attendees event-wise
              </p>
              <h3 className="text-primary fw-bold">Event-specific Currency</h3>
              <p>
                Set a different currency for each event to add multiple currency
                events.
              </p>
            </div>
          </div>

          {/* <!-- 8th --> */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center text-md-end order-md-1">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/payment.png"
                className="img-fluid row-img"
                alt="Post-Event Analytics "
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Payment Gateways</h3>
              <p>
                Stripe 3D Checkout, Authorize.net, and BitPay Payment gateway to
                accept Cryptocurrencies.
              </p>
              <h3 className="text-primary fw-bold">Discount PromoCodes</h3>
              <p>
                Create a Discount promo code that customers can apply to tickets
                while checkout.
              </p>
            </div>
          </div>
          {/* <!-- 10th --> */}
          <div className="row mx-0 my-5 py-0 py-md-5 gap-3 gap-md-0">
            <div className="col-10 col-md-5 text-center mx-auto">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Invoice+sent.png"
                className="img-fluid"
                alt="Capture Leads"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Event Short URL</h3>
              <p>Shorten Event URLs to promote them easily.</p>
              <h3 className="text-primary fw-bold">Tickets Invoice</h3>
              <p>Send ticket invoices along with booking emails.</p>
            </div>
          </div>

          {/* <!-- 8th --> */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center text-md-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/POS%2C+Manager%2C+Scanner.png"
                className="img-fluid row-img"
                alt="Post-Event Analytics "
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">POS, Manager, Scanner</h3>
              <p>
                Create separate user groups with limited access like a
                sub-organizer who can manage things like bookings, check-in, and
                selling on behalf of Organizer. POS can sell tickets on Event
                entrance, Scanner can scan tickets and Manager can manage
                everything on behalf of the Organiser.
              </p>
            </div>
          </div>
          {/* <!-- 10th --> */}
          <div className="row mx-0 my-5 py-0 py-md-5 gap-3 gap-md-0">
            <div className="col-10 col-md-5 text-center mx-auto">
              <img
                src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/Complimentary+Bookings.png"
                className="img-fluid"
                alt="Capture Leads"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Complimentary Bookings</h3>
              <p>
                Client and Organizer can create bulk/complementary bookings of
                any events that can be excluded from the normal bookings
                finances. Bulk booking is something that’s not part of Admin
                commission calculation and organiser earning payouts, these are
                kinds of free bookings that are sold outside of the website
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center text-md-end order-md-1">
              <img
                src={contactImg}
                className="img-fluid row-img"
                alt="contact us"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Explore More</h3>
              <p>
                Join a group to meet people, make friends, find support, grow a
                business, and explore your interests. Thousands of events are
                happening every day, both online and in person!
              </p>
              <p>
                For more additional details relating to Event Registration
                Management
              </p>
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  bgcolor: "#0b4995 !important",
                  mx: { xs: "auto" },
                  ml: { md: 0 },
                }}
                onClick={handleButtonClick}
              >
                Contact Us
              </Button>
            </div>
          </div>
          {/* <!-- Explore More --> */}
          {/* <div className="row mx-0 mt-5 py-0 py-md-5 gap-3 gap-md-0 justify-content-center ">
            <div className="col-12 col-md-5 d-flex justify-content-center flex-column order-1 order-md-0">
              <h3 className="text-primary fw-bold">Explore More</h3>
 <p>Join a group to meet people, make friends, find support, grow a business, and explore your interests.
                    Thousands of events are happening every day, both online and in person!</p> 
            </div>
            <div className="col-12 col-md-6 tablet overflow-hidden ">
              <div className="tablet-body">
                <Carousel controls={false} interval={2500} indicators={false}>
                  <Carousel.Item>
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/administration-bookings-edit.jpg"
                      className="d-block"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/administration-bookings.jpg"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/administration-tags.jpg"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/administration-users.jpg"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/bookings-offline-mode-on-off.jpg"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/dashboard-top-selling.jpg"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://s3.ap-south-1.amazonaws.com/vho.bizconnectevents.com/Bizconnect-Events-Images/Event+Registration/events-repetitive-daily.jpg"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div> */}
        </section>
      </div>
    </>
  );
}
