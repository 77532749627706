import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  url: null,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setUrl: (state, action) => {
      state.url = action.payload.value;
    },
  },
});
export const { setUrl } = homeSlice.actions;
export const homeState = (state) => state.home;
export default homeSlice.reducer;
