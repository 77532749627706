import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Box,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React from "react";
import Logo from "./logo.png";
import PublicIcon from "@mui/icons-material/Public";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import GroupsIcon from "@mui/icons-material/Groups";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import WebIcon from "@mui/icons-material/Web";
import MonochromePhotosIcon from "@mui/icons-material/MonochromePhotos";
import { useNavigate } from "react-router-dom";

export default function DrawerSmallScreen(props) {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const Data = [
    {
      name: "Mice App",
      path: "/mice",
      link: null,
      target: null,
      icon: <PublicIcon />,
    },
    {
      name: "Expo App",
      path: "/expo",
      link: null,
      target: null,
      icon: <LaptopMacIcon />,
    },
    {
      name: "Lead Captures",
      path: "/leadcapture",
      link: null,
      target: null,
      icon: <QrCodeScannerIcon />,
    },
    {
      name: "Chat Management",
      path: "/chatmanagement",
      link: null,
      target: null,
      icon: <MarkChatReadIcon />,
    },
    {
      name: "Virtual Event Platform",
      path: null,
      link: "https://virtual.bizconnect.space/",
      target: "_blank",
      icon: <EventAvailableIcon />,
    },
    {
      name: "Hybrid Event Platform",
      path: null,
      link: "https://virtual.bizconnect.space/hybridevent.html",
      target: "_blank",
      icon: <CardMembershipIcon />,
    },
    {
      name: "Physical Event Platform",
      path: null,
      link: "https://bizconnectevents.com/",
      target: "_blank",
      icon: <GroupsIcon />,
    },
    {
      name: "Virtual Experience Center",
      path: null,
      link: "https://bizconnect.space/",
      target: "_blank",
      icon: <FactCheckIcon />,
    },
    {
      name: " Micro Event Site Builder",
      path: "/micro-event-site-builder",
      link: null,
      target: null,
      icon: <WebIcon />,
    },
    {
      name: "File Manager",
      path: "/filemanager",
      link: null,
      target: null,
      icon: <FolderCopyIcon />,
    },
    {
      name: "Pre-event Networking",
      path: "/pre-event",
      link: null,
      target: null,
      icon: <Diversity3Icon />,
    },
    {
      name: "Event Registration Management",
      path: "/eventregistration",
      link: null,
      target: null,
      icon: <HowToRegIcon />,
    },
    {
      name: "Photo Booth",
      path: "/photobooth",
      link: null,
      target: null,
      icon: <MonochromePhotosIcon />,
    },
  ];

  return (
    <>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <Box
          onClick={() => navigate("/")}
          sx={{
            textDecoration: "none",
            cursor: "pointer",

            display: "flex",
          }}
        >
          <img
            src={Logo}
            alt="logo"
            style={{ width: 85, margin: "1rem auto" }}
          />
        </Box>
        <List>
          {props.Pages?.map(({ name, link, target, path, icon }, index) => {
            return (
              <ListItemButton
                key={name + index}
                href={link}
                target={target}
                onClick={() => {
                  setOpen(false);
                  navigate(path);
                }}
                sx={{
                  width: "100%",
                  "&:hover": {
                    color: "inherit",
                  },
                }}
              >
                {icon}
                <ListItemText primary={name} className="ps-3 w-100" />
              </ListItemButton>
            );
          })}
          <Divider component="li" />

          {Data?.map(({ name, path, link, target, icon }, index) => {
            return (
              <ListItemButton
                key={index}
                href={link}
                target={target}
                onClick={() => {
                  setOpen(false);
                  navigate(path);
                }}
              >
                {icon}
                <ListItemText primary={name} className="ps-3" />
              </ListItemButton>
            );
          })}
        </List>
      </Drawer>
      <IconButton onClick={() => setOpen(!open)}>
        <MenuIcon />
      </IconButton>
    </>
  );
}
