import React from "react";
import "./PreEvent.css";
import { Carousel } from "react-bootstrap";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
export default function PreEvent() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="PreEvent">
        <header className="container-fluid overflow-hidden px-0 position-relative">
          <div className="row h-100 align-items-center gap-4 gap-md-0 mx-0 justify-content-end justify-content-md-between">
            <div className="d-none d-md-block col-md-1 "></div>
            <div className="col-12 col-md-5 order-1 order-md-0 px-3 px-md-5">
              <h1 className="text-primary fw-bold">Pre-Event & Networking</h1>
              <p>
                Join a group to meet people, make friends, find support, grow a
                business, and explore your interests. Thousands of events are
                happening every day, both online and in person!
              </p>
            </div>
            <div className="col-8 col-md-6 px-0 my-0 ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Group+2.png"
                className="img-fluid w-100"
                alt="..."
              />
            </div>
          </div>
        </header>

        {/* <!-- Main Container --> */}
        <section className="main container-md my-5 py-0 py-md-5">
          <div className="row mx-0 my-5 gap-3 gap-md-0 ">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Secure+login.png"
                className="img-fluid row-img"
                alt="Event Registration and communication"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center text-center text-md-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">
                Event Registration and communication
              </h3>
              <p>
                {
                  " Capture your audience’s attention with a custom-designed landing page"
                }
              </p>
            </div>
          </div>
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Work+from+home.png"
                className="img-fluid row-img"
                alt="Custom Landing Page for a Great First Impression "
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">
                {" Custom Landing Page for a Great First Impression "}
              </h3>
              <p>
                Show your creativity with customizable templates to choose from
                for our event page. Add a touch of great content such as
                promotional videos, speaker bios, event agenda, and sponsor
                information to grab your audience’s attention. Pre-event hype
                goes a long way!
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Form.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">
                In-Event & Virtual Attendee Networking
              </h3>
              <p>User profile and contact card</p>
              <ul>
                <li>
                  Use the search and filter function to find relevant attendees
                </li>
                <li>
                  Share contact information online or in-person via QR code
                  Downloadable Digital Resources
                </li>
                <li>
                  Eliminate printed collateral by hosting digital versions
                  within the web app
                </li>
                <li>
                  Share multimedia resources like videos, presentations and
                  brochures
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Upload+Documents.png"
                className="img-fluid row-img"
                alt="Upload Documents"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Upload Documents</h3>
              <p>Help participants to share their travel docs etc…..</p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Messaging.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Embedded Live Chat</h3>
              <p>
                Enable your event participants to keep the conversation going
                with a chat{" "}
              </p>
              <h3 className="text-primary fw-bold">Group and Private Chat </h3>
              <p>
                Allow multiple attendees to interact with exhibitors and admins
                through text chat. Representatives and attendees can also get
                into 1:1 discussion over text, audio and video chat.
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Meeting+Scheduler.png"
                className="img-fluid row-img"
                alt="Meeting Scheduler"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center text-center">
              <h3 className="text-primary fw-bold">Meeting Scheduler</h3>
              <p>
                Let event attendees book appointments with exhibitors using the
                meeting scheduler in the exhibit booths. Exhibitors can also
                request meetings with attendees.
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Profile+Search++(Match).png"
                className="img-fluid row-img"
                alt="Profile Search  (Match)"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Profile Search (Match)</h3>
              <p>
                Let your audience use the search filter to look for people that
                match their interests, or a group of attendees with similar
                professions, educational background, etc.
              </p>
            </div>
          </div>
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center text-md-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Analytics.png"
                className="img-fluid row-img"
                alt="Post-Event Analytics "
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Post-Event Analytics </h3>
              <ul>
                <li>View attendee registrations and login metrics</li>
                <li>Add Google analytics code</li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 py-0 py-md-5 gap-3 gap-md-0">
            <div className="col-10 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/Capture+Leads.png"
                className="img-fluid "
                alt="Capture Leads"
                style={{ width: "100% !important" }}
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">
                Contactless, Easy-to-use Check-in and Badging Process
              </h3>
              <p>
                Deliver a smooth and swift check-in and badging process with our
                elegant tech stack which includes check-in and badge printing
                app, branded lanyards, iPad tablets, reg@istrations laptops,
                tablet stands, and more.
              </p>
              <h3 className="text-primary fw-bold">Capture Leads</h3>
              <p>Let booth owners decide the best profile to connect. </p>
            </div>
          </div>
        </section>
        <section>
          {/* <!-- Explore More --> */}
          <div className="row mx-0 my-5 py-0 py-md-5 gap-3 gap-md-0 justify-content-center">
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start order-1 order-md-0">
              <h3 className="text-primary fw-bold">Explore More</h3>
              <p>
                Join a group to meet people, make friends, find support, grow a
                business, and explore your interests. Thousands of events are
                happening every day, both online and in person!
              </p>
              <p>
                For more additional details relating to Pre-Event & Networking{" "}
              </p>
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  bgcolor: "#0b4995 !important",
                  mx: { xs: "auto" },
                  ml: { md: 0 },
                }}
                onClick={handleButtonClick}
              >
                Contact Us
              </Button>
            </div>
            <div className="col-12 col-md-6 tablet overflow-hidden ">
              <div className="tablet-body">
                <Carousel controls={false} interval={2500} indicators={false}>
                  <Carousel.Item>
                    <img
                      src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/1.png"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/2.png"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Pre-event/3.png"
                      className="d-block img-fluid"
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
