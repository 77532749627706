import * as React from "react";
import {
  Box,
  Typography,
  Tabs,
  Container,
  useTheme,
  useMediaQuery,
  Tab,
} from "@mui/material";
import CardCarousel from "../Cards/App";
import { useNavigate } from "react-router-dom";
import HeaderCard from "./HeaderCard";
import mapImage from "../../../assets/map.png";
import CountUp from "react-countup";
import FooterCard from "../FooterCards/App";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import MonochromePhotosIcon from "@mui/icons-material/MonochromePhotos";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import WebIcon from "@mui/icons-material/Web";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import PriceList from "./PriceList";
import { motion } from "framer-motion";
const sections = [
  {
    title: "Physical Event Platform",
    url: "https://bizconnectevents.com/",
    path: null,
    target: "_blank",
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/physical.png",
    icon: <Diversity2Icon sx={{ fontSize: "35px" }} />,
  },
  {
    title: "Hybrid Event Platform",
    url: "https://virtual.bizconnect.space/hybridevent.html",
    path: null,
    target: "_blank",
    src: "",
    icon: <CardMembershipIcon sx={{ fontSize: "35px" }} />,
  },
  {
    title: "Virtual Experience Center",
    url: "https://bizconnect.space/",
    path: null,
    target: "_blank",
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/virtual-center.png",
    icon: <EventAvailableIcon sx={{ fontSize: "35px" }} />,
  },
  {
    title: "Micro Event Site Builder",
    url: null,
    path: "/micro-event-site-builder",
    target: null,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/website.png",
    icon: <WebIcon sx={{ fontSize: "35px" }} />,
  },
  {
    title: "File Manager",
    url: null,
    path: "/filemanager",
    target: null,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/fileManager.png",
    icon: <FolderCopyIcon sx={{ fontSize: "35px" }} />,
  },
  {
    title: "Pre-event Networking",
    url: null,
    path: "/pre-event",
    target: null,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/networking.png",
    icon: <Diversity3Icon sx={{ fontSize: "35px" }} />,
  },
  {
    title: "Event Registration Management",
    url: null,
    path: "/eventregistration",
    target: null,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/registration.png",
    icon: <HowToRegIcon sx={{ fontSize: "35px" }} />,
  },
  {
    title: "Photo Booth",
    url: null,
    path: "/photobooth",
    target: null,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/photo.png",
    icon: <MonochromePhotosIcon sx={{ fontSize: "35px" }} />,
  },
];
export default function App() {
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpointIsMatch = useMediaQuery(theme.breakpoints.down("lg"));
  const reveal = {
    hiddenVariant: { y: 50, opacity: 0 },
    revealedVariant: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <>
      <Box
        sx={{
          pt: 12,
          pb: 9,
          background:
            "linear-gradient(0deg, rgba(48,78,177,1) 0%, rgba(2,19,73,1) 100%)",
        }}
      >
        <Container maxWidth="xl" sx={{ px: { md: "5rem !important" } }}>
          <Box
            className="d-flex flex-column rounded bg-white py-4 rounded-3 "
            sx={{
              position: "relative",
              boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.1)",
            }}
          >
            <motion.div
              variants={reveal}
              initial="hiddenVariant"
              animate="revealedVariant"
              transition={{
                ease: "easeIn",
                type: "tween",
                staggerChildren: 0.1,
                duration: 0,
                delayChildren: 0.4,
              }}
            >
              <Box
                className="rounded-3 mx-auto bg-white"
                sx={{
                  position: "absolute",
                  top: {
                    xs: "-10%",
                    md: "-19%",
                  },
                  left: "50%",
                  transform: "translate(-50%,22%)",
                  zIndex: 5,
                  boxShadow: "0 1px 7px 0 rgba(0,0,0,0.1)",
                  width: {
                    xs: "90%",
                    md: "70%",
                    lg: "83%",
                  },
                }}
              >
                <Tabs
                  variant="scrollable"
                  sx={{
                    "& .MuiTabs-scrollButtons.Mui-disabled ": {
                      opacity: 0.3,
                    },
                  }}
                >
                  {sections?.map((item, index) => {
                    return (
                      <Tab
                        component="a"
                        href={item.url}
                        target={item.target}
                        onClick={() => navigate(item.path)}
                        key={index}
                        icon={
                          <motion.div variants={reveal}>{item.icon}</motion.div>
                        }
                        sx={{
                          maxWidth: "142px",
                          textDecoration: "none",
                          textTransform: "capitalize",
                          color: "#0c0c0c",
                          fontWeight: "bold",
                          "&:hover": {
                            color: "#F47437",
                          },
                          justifyContent: "start",
                          // px: 1,
                        }}
                        label={
                          <motion.div variants={reveal}>
                            <Typography
                              variant="caption"
                              component="p"
                              className="lh-sm"
                            >
                              {item.title}
                            </Typography>
                          </motion.div>
                        }
                      />
                    );
                  })}
                </Tabs>
              </Box>
            </motion.div>
            {/* Carousel */}
            <Box className="px-3 py-2 ">
              <motion.div
                initial={{ y: 50 }}
                animate={{ y: 0 }}
                transition={{
                  ease: "easeIn",
                  type: "tween",
                  duration: 0.7,
                }}
              >
                <CardCarousel />
              </motion.div>
            </Box>
            {/* <Button
              variant="contained"
              className="rounded-pill fw-bold"
              size="large"
              sx={{
                position: "absolute",
                bottom: {
                  xs: "-4%",
                  md: "-5%",
                },
                left: "50%",
                transform: "translate(-50%,8%)",
                zIndex: 5,
                background:
                  // "linear-gradient(90deg, #F47437 23%, rgba(4,41,167,1) 83%)",
                  "linear-gradient(90deg, rgba(244,116,55,1) 23%, rgba(251,165,125,1) 83%)",
                px: { md: 5 },
                color: "#000",
              }}
            >
              Explore more
            </Button> */}
          </Box>
        </Container>
      </Box>

      <Box
        className="bg-light"
        sx={{
          display: "flex",
          flexDirection: { xs: "column" },
          width: "100%",
          margin: { xs: "0.5em 0", lg: " 0 0" },
          gap: { xs: 2, lg: 0 },
          py: {
            xs: 0,
            lg: 5,
          },
        }}
      >
        <Container maxWidth="xl" sx={{ px: { xs: 0, md: "auto" } }}>
          {/* pricing */}
          <Box
            className="d-flex flex-column px-md-5 px-3 py-4 my-5 my-md-0 rounded"
            sx={{
              background: "#fff",
              color: "#000",
              boxShadow: "rgba(13, 110, 253, 0.12) 0px 2px 10px",
            }}
          >
            <Typography
              component="p"
              variant="h4"
              sx={{
                textShadow: "0 2px 2px rgba(0,0,0,0.25)",
                color: "#0b4995",
              }}
              className="fw-bold text-center mb-4"
            >
              Pricing
            </Typography>
            <Box className="d-flex gap-4 p-0 flex-wrap justify-content-center ">
              {PriceList?.map((item, index) => {
                return (
                  <Box
                    sx={{ width: { xs: "100%", lg: "calc(100% / 2.1)" } }}
                    key={index}
                  >
                    <HeaderCard
                      title={item.title}
                      body={item.body}
                      src={item.src}
                      licence={item.licence}
                      list={item.list}
                      pricing={item.pricing}
                    />
                  </Box>
                );
              })}
            </Box>
          </Box>

          {/* footer Carousel */}
          <Container
            maxWidth="xl"
            className="my-md-5 py-3"
            sx={{ background: "#fff" }}
          >
            <Typography
              component="div"
              variant="h4"
              sx={{
                textShadow: "0 4px 4px rgba(0,0,0,0.25)",
                color: "#0b4995",
              }}
              className="fw-bold text-center mb-3"
            >
              Our Product Features
            </Typography>
            <FooterCard />
          </Container>

          {/* map */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              my: 5,
            }}
          >
            {/* <Typography variant="h6" component="p">
              Footprints
            </Typography> */}
            <Typography variant="h3" component="h5" className="text-center">
              Our Brilliant Work Reaches Every Corner Of The Globe
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  md: "row",
                },
                justifyContent: "center",
                alignItems: "center",
                gap: {
                  xs: 3,
                  md: 7,
                },
                mt: { md: 5 },
                px: { md: 5 },
              }}
            >
              <Typography
                variant="subtitle1"
                component="p"
                className="text-center"
              >
                <Typography
                  variant="h3"
                  component="h5"
                  className=" counter fw-bold my-2"
                  data-target="150"
                  style={{ color: "#F47437" }}
                >
                  <CountUp end={25} enableScrollSpy={true} />+
                </Typography>
                Years of Proven Track Record
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className="text-center"
              >
                <Typography
                  variant="h3"
                  component="h5"
                  className=" counter fw-bold my-2"
                  data-target="150"
                  style={{ color: "#F47437" }}
                >
                  <CountUp end={600} enableScrollSpy={true} />+
                </Typography>
                Clients Worldwide
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className="text-center"
              >
                <Typography
                  variant="h3"
                  component="h5"
                  className=" counter fw-bold my-2"
                  data-target="150"
                  style={{ color: "#F47437" }}
                >
                  <CountUp end={2000} enableScrollSpy={true} />+
                </Typography>
                Successful Events & Exhibitions
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className="text-center"
              >
                <Typography
                  variant="h3"
                  component="h5"
                  className=" counter fw-bold my-2"
                  data-target="150"
                  style={{ color: "#F47437" }}
                >
                  <CountUp end={150} enableScrollSpy={true} />+
                </Typography>
                TV Studio Projects
              </Typography>
              <Typography
                variant="subtitle1"
                component="p"
                className="text-center"
              >
                <Typography
                  variant="h3"
                  component="h5"
                  className=" counter fw-bold my-2"
                  data-target="150"
                  style={{ color: "#F47437" }}
                >
                  <CountUp end={250} enableScrollSpy={true} />+
                </Typography>
                Interior and Retail Projects
              </Typography>
            </Box>
            <Box className="d-flex">
              <img
                src={mapImage}
                className="img-fluid mx-auto w-75"
                alt="map-png"
              />
            </Box>
          </Box>
        </Container>
        {/* </Box> */}
      </Box>
    </>
  );
}
