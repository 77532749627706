import * as React from "react";
import {
  Box,
  Toolbar,
  Container,
  Button,
  useMediaQuery,
  useTheme,
  Menu,
  MenuItem,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Logo from "./logo.png";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import DrawerSmallScreen from "./DrawerSmallScreen";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CategoryIcon from "@mui/icons-material/Category";
import AddTaskIcon from "@mui/icons-material/AddTask";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import BookIcon from "@mui/icons-material/Book";
import PhoneIcon from "@mui/icons-material/Phone";
import { motion } from "framer-motion";

const PAGES = [
  {
    name: "Home",
    path: "/",
    link: null,
    target: null,
    icon: <HomeIcon />,
    submenu: null,
  },
  {
    name: "About Us",
    path: "/aboutus",
    link: null,
    target: null,
    icon: <InfoIcon />,
    submenu: null,
  },
  {
    name: "Products",
    path: null,
    link: null,
    target: null,
    icon: <CategoryIcon />,
    submenu: [
      {
        name: "Mice",
        path: "/mice",
        url: null,
        target: null,
      },
      {
        name: "Expo",
        path: "/expo",
        url: null,
        target: null,
      },
      {
        name: "Lead Capture",
        path: "/leadcapture",
        url: null,
        target: null,
      },
      {
        name: "Chat Management",
        path: "/chatmanagement",
        url: null,
        target: null,
      },
      {
        name: "Virtual Event Platform",
        path: null,
        url: "https://virtual.bizconnect.space/",
        target: "_blank",
      },
      {
        name: "Hybrid Event Platform",
        path: null,
        url: "https://virtual.bizconnect.space/hybridevent.html",
        target: "_blank",
      },
      {
        name: "Physical Event Platform",
        path: null,
        url: "https://bizconnectevents.com/",
        target: "_blank",
      },
      {
        name: "Virtual Experience Center",
        path: null,
        url: "https://bizconnect.space/",
        target: "_blank",
      },
      {
        name: "Website Builder",
        path: "/micro-event-site-builder",
        url: null,
        target: null,
      },
      {
        name: "File Manager",
        path: "/filemanager",
        url: null,
        target: null,
      },
      {
        name: "Pre-event Networking",
        path: "/pre-event",
        url: null,
        target: null,
      },
      {
        name: "Event Registration Management",
        path: "/eventregistration",
        url: null,
        target: null,
      },
      {
        name: "Photo Booth",
        path: "/photobooth",
        url: null,
        target: null,
      },
    ],
  },
  {
    name: "Blogs",
    path: null,
    link: "https://blog.bizconnect.events/",
    target: "_blank",
    icon: <BookIcon />,
    submenu: null,
  },
  {
    name: "Contact Us",
    path: "/contactus",
    link: null,
    target: null,
    icon: <PhoneIcon />,
    submenu: null,
  },
];
const themeMenu = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          backgroundColor: "white",
          borderBottom: 0,
        },
      },
    },
  },
});
function ResponsiveAppBar() {
  const navigate = useNavigate();
  const theme = useTheme();
  const breakpointIsMatch = useMediaQuery(theme.breakpoints.down("md"));

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event, submenu) => {
    if (submenu === null) {
      return false;
    } else {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const reveal = {
    hiddenVariant: { y: 50, opacity: 0 },
    revealedVariant: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: "0 1px 7px 0 rgba(0,0,0,0.4)",
        backgroundColor: "#fff",
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            onClick={() => navigate("/")}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              textDecoration: "none",
              cursor: "pointer",
            }}
          >
            <img src={Logo} alt="logo" style={{ width: "80px" }} />
          </Box>

          <Box
            onClick={() => navigate("/")}
            sx={{
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              textDecoration: "none",
            }}
          >
            <img src={Logo} alt="logo" style={{ width: "60px" }} />
          </Box>

          {breakpointIsMatch ? <DrawerSmallScreen Pages={PAGES} /> : null}
          <Box
            sx={{
              flexGrow: 0,
              display: { xs: "none", md: "flex" },
              ml: "auto",
            }}
          >
            {PAGES.map(({ name, path, link, target, submenu, icon }, index) => (
              <>
                <Button
                  startIcon={icon}
                  key={name + index}
                  href={link}
                  target={target}
                  onClick={(event) => {
                    navigate(path);
                    handleClick(event, submenu);
                  }}
                  aria-controls={
                    submenu === null ? null : open ? "basic-menu" : undefined
                  }
                  aria-haspopup="false"
                  aria-expanded={
                    submenu === null ? null : open ? "true" : undefined
                  }
                  sx={{
                    my: 2,
                    color: "black",
                    "&:hover": {
                      color: "#0B4995",
                    },
                    px: 1.5,
                  }}
                >
                  {name}
                </Button>

                <ThemeProvider theme={themeMenu}>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/mice");
                        handleClose();
                      }}
                    >
                      Mice
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/expo");
                        handleClose();
                      }}
                    >
                      Expo
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/leadcapture");
                        handleClose();
                      }}
                    >
                      Lead Capture
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/chatmanagement");
                        handleClose();
                      }}
                    >
                      Chat Management
                    </MenuItem>
                    <MenuItem
                      divider
                      component="a"
                      onClick={handleClose}
                      href="https://virtual.bizconnect.space/"
                      target="_blank"
                      sx={{
                        color: "inherit !important",
                      }}
                    >
                      Virtual Event Platform
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={handleClose}
                      component="a"
                      href="https://virtual.bizconnect.space/hybridevent.html"
                      target="_blank"
                      sx={{
                        color: "inherit !important",
                      }}
                    >
                      Hybrid Event Platform
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={handleClose}
                      component="a"
                      href="https://bizconnectevents.com/"
                      target="_blank"
                      sx={{
                        color: "inherit !important",
                      }}
                    >
                      Physical Event Platform
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={handleClose}
                      component="a"
                      href="https://bizconnect.space/"
                      target="_blank"
                      sx={{
                        color: "inherit !important",
                      }}
                    >
                      Virtual Experience Center
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/micro-event-site-builder");
                        handleClose();
                      }}
                    >
                      Micro Event Site Builder
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/filemanager");
                        handleClose();
                      }}
                    >
                      File Manager
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/pre-event");
                        handleClose();
                      }}
                    >
                      Pre-event Networking
                    </MenuItem>
                    <MenuItem
                      divider
                      onClick={() => {
                        navigate("/eventregistration");
                        handleClose();
                      }}
                    >
                      Event Registration Management
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        navigate("/photobooth");
                        handleClose();
                      }}
                    >
                      Photo Booth
                    </MenuItem>
                  </Menu>
                </ThemeProvider>
              </>
            ))}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
