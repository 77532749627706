import React from "react";
import "./Mice.css";
import Carousel from "react-bootstrap/Carousel";
import contactImg from "../../../assets/contactUs.png";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

export default function Mice() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="Mice">
        <section
          id="header"
          className="d-flex justify-content-center align-items-center my-md-5 pt-md-5"
          style={{ height: "max-content", width: "100%" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-6 order-md-second mt-0 ">
                <Carousel controls={false} interval={2500}>
                  <Carousel.Item className="bg-none p-3">
                    <div
                      className="card rounded-3 position-relative border-0 shadow"
                      style={{ minHeight: "180px" }}
                    >
                      <div className="card-body ">
                        <h2 className="fw-bold root-primary">01</h2>
                        <p className="">
                          Want to create a micro site in a jiffy AND convert it
                          into a App?
                        </p>
                      </div>
                      <span className="icon-Q fw-semibold position-absolute root-tertiary">
                        ?
                      </span>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="bg-none p-3">
                    <div
                      className="card rounded-3 position-relative border-0 shadow "
                      style={{ minHeight: "180px" }}
                    >
                      <div className="card-body ">
                        <h2 className="fw-bold root-primary">02</h2>
                        <p className="">
                          Can delegates book appointments & register hassle-free
                          on the microsite?
                        </p>
                      </div>
                      <span className="icon-Q fw-semibold position-absolute root-tertiary">
                        ?
                      </span>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="bg-none p-3">
                    <div
                      className="card rounded-3 position-relative border-0 shadow "
                      style={{ minHeight: "180px" }}
                    >
                      <div className="card-body ">
                        <h2 className="fw-bold root-primary">03</h2>
                        <p className="">
                          Can delegates Captured Leads, maintain meeting data &
                          make note in one place
                        </p>
                      </div>
                      <span className="icon-Q fw-semibold position-absolute root-tertiary">
                        ?
                      </span>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="bg-none p-3">
                    <div
                      className="card rounded-3 position-relative border-0 shadow "
                      style={{ minHeight: "180px" }}
                    >
                      <div className="card-body ">
                        <h2 className="fw-bold root-primary">04</h2>
                        <p className="">
                          Can your delegates check Exhibitors, Connect & Book
                          Appoint with them from a single App Even when event is
                          over
                        </p>
                      </div>
                      <span className="icon-Q fw-semibold position-absolute root-tertiary">
                        ?
                      </span>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="bg-none p-3">
                    <div
                      className="card rounded-3 position-relative border-0 shadow"
                      style={{ minHeight: "180px" }}
                    >
                      <div className="card-body ">
                        <h2 className="fw-bold root-primary">05</h2>
                        <p className="">
                          Do your delegates want to safely upload their
                          documents on the micro website without data breach?
                        </p>
                      </div>
                      <span className="icon-Q fw-semibold position-absolute root-tertiary">
                        ?
                      </span>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="bg-none p-3">
                    <div
                      className="card rounded-3 position-relative border-0 shadow "
                      style={{ minHeight: "180px" }}
                    >
                      <div className="card-body ">
                        <h2 className="fw-bold root-primary">06</h2>
                        <p className="">
                          Can you trigger alerts & updates on the app & answer
                          questions on email?
                        </p>
                      </div>
                      <span className="icon-Q fw-semibold position-absolute root-tertiary">
                        ?
                      </span>
                    </div>
                  </Carousel.Item>
                  <Carousel.Item className="bg-none p-3">
                    <div
                      className="card rounded-3 position-relative border-0 shadow "
                      style={{ minHeight: "180px" }}
                    >
                      <div className="card-body ">
                        <h2 className="fw-bold root-primary">07</h2>
                        <p className="">
                          Wondering how to add group & private chat features
                          between delegates & speakers in the micro website?
                        </p>
                      </div>
                      <span className="icon-Q fw-semibold position-absolute root-tertiary">
                        ?
                      </span>
                    </div>
                  </Carousel.Item>
                </Carousel>
              </div>
              <div className="col-md-6 order-md-first mt-md-auto mt-5 ">
                <div className="content text-break ">
                  <h2 className="fw-bold fs-1 root-primary mb-3">
                    MICE App - Unlock Incentives{" "}
                  </h2>
                  <p className="fw-bold text-body-secondary ">
                    Join a group to meet people, make friends, find support,
                    grow a business, and explore your interests. Thousands of
                    events are happening every day, both online and in person!
                  </p>
                  <button className="btn btn-root me-2">
                    <i className="fa-brands fa-google-play"></i> Android
                  </button>
                  <button className="btn btn-root px-4">
                    <i className="fa-brands fa-apple"></i> IOS
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mt-md-5 mt-5 pt-md-5">
            <div className="row gy-2 justify-content-between ">
              <div className="col-12 align-items-center">
                <div className="content-wrapper d-flex flex-column justify-content-center align-items-center ">
                  <h2 className="fw-bold fs-2 root-primary mb-3 mb-md-0 text-center">
                    BizConnect – Your All inclusive MICE App
                  </h2>
                  <div
                    className="card-image bg-white d-flex justify-content-center align-items-center p-md-5"
                    style={{ width: "80%", height: "80%" }}
                  >
                    <img
                      src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Img3d.png"
                      alt="Micro website/ App"
                      className="w-100 h-100 "
                      style={{ aspectRatio: "12/7" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" className="pt-md-5 mt-md-5 ">
          <div className="container ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-6 align-items-center ">
                <h2 className="fw-bold fs-2 root-primary mb-3 d-md-none d-block">
                  Micro Website and App
                </h2>
                <div className="card-image bg-white">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/WebApp.png"
                    alt="Micro website/ App"
                    className="w-100 h-100 float-start"
                  />
                </div>
              </div>
              <div className=" col-md-6 d-flex align-items-center ">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3 d-none d-md-block">
                    Micro Website and App{" "}
                  </h2>
                  <p className="fw-bold text-body-secondary ">
                    Every event needs a micro website to inform and update your
                    audiences, exhibitors, and speakers about the upcoming
                    event.
                  </p>
                  <p className="text-body-secondary">
                    One of the first things to capture your audience’s attention
                    is a captivating landing page. Our tools are designed to
                    understand your event needs and designing a custom landing
                    page for your event microsite, ensuring that it is engaging
                    and unique enough to capture your audience’s attention
                  </p>
                  <p className="fw-bold text-body-secondary">
                    A multitude of benefits for your event on our Micro website/
                    App
                  </p>
                  <p className="text-body-secondary">
                    Whether you are hosting an in-person event or an online one,
                    we provide an ideal microsite where visitors, speakers, and
                    participants can network, make groups, and book appointments
                    with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container mt-5 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-6 order-md-second  ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Network.png"
                    alt="Networking"
                    className="w-md-75 w-100 float-md-end float-center"
                  />
                </div>
              </div>
              <div className="col-md-6 order-md-first ">
                <div className="content">
                  <h2 className="fw-bold fs-2 root-primary d-none d-md-block mb-3">
                    Networking
                  </h2>
                  <p className="text-body-secondary">
                    An in-person or online event is the best place to network
                    for your business or career. Our event microsites offer
                    various interactive options to increase your networking
                    opportunities, meet people, make friends, engage in private
                    or group discussions, share business cards, and explore your
                    interests.
                  </p>
                  <p className="text-body-secondary">
                    You can also view user profiles and contact cards, and use a
                    search and filter function to find relevant attendees. We
                    provide options for sharing contact information online or
                    in-person using QR codes and Downloadable Digital Resources.
                    Plus, you can share multimedia resources like videos,
                    presentations, and brochures with ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container r mt-4 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5 align-items-center ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Agenda.png"
                    alt="Programs & Agenda"
                    className="w-md-75 w-100 float-start"
                  />
                </div>
              </div>
              <div className=" col-md-6 d-flex align-items-center ">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Programs & Agenda
                  </h2>
                  <p className="text-body-secondary">
                    Create a list of programs and the agenda for each unique
                    event. It helps increase the output of each meeting or event
                    and eliminates the waste of time. It also increases the
                    efficiency and productivity of the speakers and exhibitors
                    of the event.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container l mt-4">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5 order-md-second  ">
                <div className="card-image ">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Onsite.png"
                    alt="Registration Online"
                    className="w-md-75 w-100 float-end"
                  />
                </div>
              </div>
              <div className="col-md-6 order-md-first d-flex align-items-center">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Registration Online/ Onsite Speakers/ Exhibitors/ Delegates
                  </h2>
                  <p className="text-body-secondary">
                    Speakers, exhibitors, and artists can register conveniently
                    on the microsite in just a few clicks. The registration
                    process is designed to be extremely user-friendly and
                    simple, allowing everyone to register for the event with
                    ease.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container r mt-4 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5  ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Contactless.png"
                    alt="Onsite Contactless"
                    className="w-md-75 w-100 float-start"
                  />
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center ">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Onsite Contactless, Easy-to-use To Check-in
                  </h2>
                  <p className="text-body-secondary">
                    Gone are the days when attendees, speakers, and exhibitors
                    at an event would have to fuss over the check-in and badging
                    process. Here, we offer an elegant tech stack that includes
                    check-ins, branded lanyards, iPad tablets, tablet stands,
                    registration laptops, and more, enabling a swift and smooth
                    check-in and badging process for your event.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container l mt-4 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5 order-md-second  ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Listing.png"
                    alt="Listing Exhibitors"
                    className="w-md-75 w-100 h-100 float-end"
                  />
                </div>
              </div>
              <div className="col-md-6 order-md-first d-flex flex-column justify-content-center">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Listing Exhibitors
                  </h2>
                  <p className="text-body-secondary">
                    Every event has multiple exhibitors. On this app, you can
                    click on each exhibitor's individual tab, where you can find
                    out all the information about them. You can even schedule
                    appointments on a calendar and send them messages through
                    this app. This will be available even after the event is
                    over
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container r mt-4">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5  ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Profile.png"
                    alt="Profile Viewing"
                    className="w-md-75 w-100 h-100 float-start"
                  />
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center ">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Profile Viewing{" "}
                  </h2>
                  <p className="text-body-secondary">
                    Our customized profile viewing section allows anyone
                    attending the event to view each other’s profiles, and bio
                    data, and learn more about them. You can even engage in a
                    private chat or a group chat with the person by choosing the
                    different interactive options on their profile.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container l mt-5 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5 order-md-second  ">
                <div className="card-image h-100">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Capture+.png"
                    alt="Capture Leads"
                    className="w-md-75 w-100 float-end"
                  />
                </div>
              </div>
              <div className="col-md-6 order-md-first d-flex flex-column justify-content-center">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Capture Leads Speakers / Exhibitors / Delegates
                  </h2>
                  <p className="text-body-secondary">
                    Attendees, exhibitors, speakers, and visitors are given a
                    Unique QR code containing all the vital information -
                    Contact details, company name, email ID, phone number, etc.
                    The Lead Generation tab on our app opens a scanner that you
                    can use to scan anyone's unique QR code, giving you all the
                    information, you need about the person.{" "}
                  </p>
                  <p className="text-body-secondary">
                    You can also add personal notes to this app to help you
                    remember details about the person, thus, enhancing your lead
                    generation. No matter how many people you meet, you can have
                    all the details with ease on this app
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container r mt-5 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5  ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/AI.png"
                    alt="AI-Based Matchmaking"
                    className="w-md-75 w-100 h-100 float-start"
                  />
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center ">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    AI-Based Matchmaking
                  </h2>
                  <p className="text-body-secondary">
                    One of the most interesting aspects of our event micro
                    website is the inclusion of an AI-based matchmaking option.
                    This uses an AI software to instantly match two similar
                    profiles, or match profiles using keywords typed into the
                    search bar.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container l mt-4 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5 order-md-second  ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Post.png"
                    alt="Post-Event Analytics"
                    className="w-md-75 w-100 h-100 float-end"
                  />
                </div>
              </div>
              <div className="col-md-6 order-md-first d-flex flex-column justify-content-center">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Post-Event Analytics
                  </h2>
                  <p className="text-body-secondary">
                    Our Pre Event tool allows you to easily check attendee
                    registration and login metrics by embedding Google Analytics
                    code within the site. This helps you understand attendee
                    engagement and make any required changes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container r mt-4 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5  ">
                <div className="card-image">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/Upload-2.png"
                    alt="Upload Documents"
                    className="w-100 h-100 float-start"
                  />
                </div>
              </div>
              <div className="col-md-6 d-flex flex-column justify-content-center ">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Upload Documents
                  </h2>
                  <p className="text-body-secondary">
                    Delegates can easily & safely upload their documents on the
                    microsite without data breach. This could be white papers,
                    Contest submissions or even passports or other travel
                    documents
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container l mt-4 ">
            <div className="row gy-2 justify-content-between ">
              <div className="col-md-5 order-md-second  ">
                <div className="card-image">
                  <img
                    src={contactImg}
                    alt="contact us"
                    className="w-md-75 w-100 h-100 float-end"
                  />
                </div>
              </div>
              <div className="col-md-6 order-md-first d-flex flex-column justify-content-center">
                <div className="content text-break">
                  <h2 className="fw-bold fs-2 root-primary mb-3">
                    Explore more
                  </h2>
                  <p className="text-body-secondary">
                    Thousands of events are happening every day, both online and
                    in person!
                    <br /> For more additional details relating to MICE
                  </p>
                  <Button
                    size="small"
                    variant="contained"
                    endIcon={<ArrowForwardIcon />}
                    sx={{ bgcolor: "#0b4995 !important" }}
                    onClick={handleButtonClick}
                  >
                    Contact Us
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="pt-5 my-md-5 ">
          <div className="container">
            <div className="row">
              <div className="col-12 ">
                <h2 className="fw-bold fs-3 root-primary mb-3 text-center">
                  Watch Quick Demo
                </h2>
                <div className="video-wrapper">
                  <video
                    autoPlay
                    muted
                    loop
                    className="bg-vedio w-100 h-100 rounded-1 border"
                    style={{ aspectRatio: "16/7" }}
                  >
                    <source src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Mice/MICE_REC.mp4" />
                  </video>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
