import React from "react";
import { Route, Routes } from "react-router-dom";
import RootPage from "./Components/routes/Route";
// import "./Components/component/Contact-Us/contact";
function App() {
  return (
    <>
      <Routes>
        <Route>
          {/* Frontend Ui */}
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/register" element={<Register />} /> */}
          <Route path="*" element={<RootPage />} />
          {/* Backend Ui */}
        </Route>
      </Routes>
    </>
  );
}

export default App;
