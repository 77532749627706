import React from "react";
import "./WebsiteBuilder.css";
import contactImg from "../../../assets/contact.png";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

export default function WebsiteBuilder() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="WebsiteBuilder">
        <header className="container-fluid overflow-hidden px-0 position-relative">
          <div className="row h-100 align-items-center gap-4 gap-md-0 mx-0 justify-content-end justify-content-md-between">
            <div className="d-none d-md-block col-md-1 "></div>
            <div className="col-12 col-md-5 order-1 order-md-0 px-3 px-md-5">
              <h1 className="text-primary fw-bold">
                {"  Architect - HTML and Site Builder"}
              </h1>
              <p>
                A powerful Micro Event Site Builder that does all the job for
                you. Feature laden with templates, an advanced drag and drop
                system, Dark Mode and a dynamic editor makes it among the very
                best.
              </p>
            </div>
            <div className="col-8 col-md-6 px-0 my-0 ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/header.png"
                className="img-fluid w-100"
                alt=""
              />
            </div>
          </div>
        </header>

        {/* <!-- Main Container --> */}

        <section className="main container-md my-5 py-0 py-md-5">
          <div className="row mx-0 my-5 gap-3 gap-md-0 ">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Work+from+home.png"
                className="img-fluid row-img"
                alt="Event Registration and communication"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center text-center text-md-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Easy to use</h3>
              <p>No coding, easy to create and update</p>
              <h3 className="text-primary fw-bold">Text Editor</h3>
              <p>Double click on any text content and modify</p>
              <h3 className="text-primary fw-bold">Live Editing</h3>
              <p>
                easily, visually and in real-time edit most of elements
                properties
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Flexible+template+based+landing+page.png"
                className="img-fluid row-img"
                alt="Custom Landing Page for a Great First Impression "
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Publish and Export </h3>
              <p>
                Download whole project, publish it to a remote server via FTP or
                attach custom domains/subdomains to display projects
              </p>
              <h3 className="text-primary fw-bold">Templates </h3>
              <p>Architect comes with over 20 pre-built templates </p>
              <h3 className="text-primary fw-bold">Flexible Elements System</h3>
              <p>
                Use one of over 40 elements included with architect (and more to
                come in free future updates) or easily create your own custom
                elements
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Form.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Advanced Drag & Drop</h3>
              <p>
                Easily move elements in the builder by dragging and dropping{" "}
              </p>
              <h3 className="text-primary fw-bold">Undo Manager</h3>
              <p>
                Made a mistake or want to step back to a previous iteration? No
                problem, use the integrated undo manager to undo and redo any
                action in architect
              </p>
              <h3 className="text-primary fw-bold">Editable Landing Page </h3>
              <p>
                Default landing page can be easily edited via built-in
                appearance editor with live preview and no coding knowledge
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Menu+Editor.png"
                className="img-fluid row-img"
                alt="Upload Documents"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center">
              <h3 className="text-primary fw-bold">High Performanc</h3>
              <p>
                Architect is lightweight and has lighting fast performance and
                page load time out of the box
              </p>
              <h3 className="text-primary fw-bold">Dark Mode</h3>
              <p>Architect comes with pre-built light and dark themes</p>
              <h3 className="text-primary fw-bold">Custom Pages</h3>
              <p>Custom pages can be created using built-in WISIWYG editor</p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Appearance+Editor.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Menu Editor</h3>
              <p>
                All the menus across the site can be easily edited and reordered
                via drag and drop
              </p>
              <h3 className="text-primary fw-bold">Appearance Editor</h3>
              <p>
                Easily manage your site appearance (colors, logo, landing page,
                menus etc){" "}
              </p>
              <h3 className="text-primary fw-bold">Ads </h3>
              <p>
                {" "}
                Paste ad codes into integrated ad slots in admin panel and
                Architect will display them automatically
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Analytics.png"
                className="img-fluid row-img"
                alt="Meeting Scheduler"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center text-center">
              <h3 className="text-primary fw-bold">Roles & Permissions</h3>
              <p>
                Assign roles and permissions to users to give or restrict access
                to specific functionality on the site.
              </p>
              <h3 className="text-primary fw-bold">Analytics </h3>
              <p>Google analytics are integrated right into admin panel</p>
              <h3 className="text-primary fw-bold">Translatable </h3>
              <p>
                Site can be easily translated from admin panel, it also has
                multi-language support.
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Profile+Search++(Match).png"
                className="img-fluid row-img"
                alt="Profile Search  (Match)"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Responsive</h3>
              <p>
                Architect is fully responsive and will work on desktop, mobile,
                tablet and other devices.
              </p>
              <h3 className="text-primary fw-bold">Documentation </h3>
              <p>Architect comes with in depth documentation </p>
              <h3 className="text-primary fw-bold">SEO Editor</h3>
              <p> SEO tags for all pages can be modified directly</p>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Professional+Design.png"
                className="img-fluid row-img"
                alt="Meeting Scheduler"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center text-center">
              <h3 className="text-primary fw-bold">Authentication</h3>
              <p>
                Fully featured authentication system with social login
                (facebook, twitter and google), normal login, registration,
                password recovery, account settings and more.
              </p>
              <h3 className="text-primary fw-bold">Professional Design</h3>
              <p>
                Pixel-perfect professional design based on google’s material
                design. Settings – Admin panel has many settings that allow you
                to fine-tune the site to your needs.
              </p>
              <h3 className="text-primary fw-bold">Multiple Homepages</h3>
              <p>
                Choose between multiple home pages including landing page, login
                page or your own custom html page.
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-6  order-md-1 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Website+Builder/Context+Menu.png"
                className="img-fluid row-img"
                alt="Profile Search  (Match)"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Context Menu</h3>
              <p>
                Right click on any element to bring out the context menu with a
                variety of general options like moving elements up and down with
                more precision when drag and drop{" "}
              </p>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src={contactImg}
                className="img-fluid row-img"
                alt="contact us"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Information</h3>
              <p>
                For more additional details relating to Micro Event Site Builder{" "}
              </p>
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  bgcolor: "#0b4995 !important",
                  mx: { xs: "auto" },
                  ml: { md: 0 },
                }}
                onClick={handleButtonClick}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
