import {
  Box,
  Card,
  Container,
  Divider,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import sbi from "./assets/sbi.png";
import hitachi from "./assets/hitachi.png";
import gupta from "./assets/gupta.jpg";
import lg from "./assets/lg.jpg";
import eblu from "./assets/eblu.jpg";
import indianOil from "./assets/indianOil.jpg";

const collection = [
  {
    title: "SBIT Mutual Fund",
    url: "https://www.youtube.com/embed/VvKiwJnaWNA",
    img: sbi,
  },
  {
    title: "Hitachi's Chennai Plant's Inauguration Event",
    url: "https://www.youtube.com/embed/TXIXU6H6HmY",
    img: hitachi,
  },
  {
    title: "Gupta Power Stand made by BizConnect at Elecrama 2023",
    url: "https://www.youtube.com/embed/EJnFNoLuWaE",
    img: gupta,
  },
  {
    title: "GCC Pharma Summit Dubai",
    url: "https://www.youtube.com/embed/5JuPqgFSIb0",
    img: "https://virtual.bizconnect.space/img/Recent-event/gcc-pharma.webp",
  },
  {
    title: "LG award function",
    url: "https://www.youtube.com/embed/jDXgG4yhQFE",
    img: lg,
  },
  {
    title: "Indian Oil 3d I BizConnect Virtual Platform",
    url: "https://www.youtube.com/embed/oD_z8MD7KqU",
    img: indianOil,
  },
  {
    title: "Iblu road show",
    url: "https://www.youtube.com/embed/TMSiSoWcwDE",
    img: eblu,
  },
  {
    title: "Tata Steel Virtual Annual Meet",
    url: "https://www.youtube.com/embed/n8ZvQ4t_zNU",
    img: "https://virtual.bizconnect.space/img/Recent-event/tata-steel.webp",
  },
  {
    title: "Goodricke Excellence Awards Ceremony",
    url: "https://www.youtube.com/embed/UBOb8yMgA28",
    img: "https://virtual.bizconnect.space/img/Recent-event/Goodricke-Excellence.webp",
  },
  {
    title: "Forum de Manufatura - Latin American 360 Virtual Event",
    url: "https://www.youtube.com/embed/qio5gn2VHgM",
    img: "https://virtual.bizconnect.space/img/Recent-event/forumde-latin-america.webp",
  },
  {
    title: "AWS Partner Summit",
    url: "https://www.youtube.com/embed/avHzur9LttE",
    img: "https://virtual.bizconnect.space/img/Recent-event/aws-partner.webp",
  },
];

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: {
    xs: "90%",
    md: "50%",
  },
  height: {
    xs: "50%",
    md: "60%",
  },
  bgcolor: "background.paper",
  border: "0 !important",
  boxShadow: 24,
  borderRadius: 1,
  p: 2,
};

export default function WorkSection() {
  const theme = useTheme();
  const maxMd = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(false);
  const [selectedItem, setSelectedItem] = React.useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <Container maxWidth="xl" className="my-md-5 my-3">
        <Typography
          component="h4"
          variant="h4"
          sx={{
            textShadow: "0 4px 4px rgba(0,0,0,0.25)",
            color: "#0b4995",
          }}
          className="fw-bold mb-md-5 mb-3 text-center "
        >
          Our Works Shows
        </Typography>
        <Box className="d-flex justify-content-center align-items-center flex-wrap gap-4">
          {(maxMd ? collection.slice(0, 5) : collection).map(
            ({ title, url, img }, index) => {
              return (
                <Card
                  key={index}
                  sx={{
                    flexBasis: {
                      xs: "100%",
                      md: "23%",
                    },
                    alignSelf: "normal",
                    border: 0,
                    boxShadow: 0,
                  }}
                  onClick={() => {
                    handleOpen();
                    setSelectedItem(url);
                  }}
                >
                  <Box className="d-flex flex-column">
                    <img src={img} className="img-fluid zoom" alt="webinar" />
                    <Typography
                      variant="subtitle1"
                      component="h6"
                      className="text-center mt-2"
                    >
                      {title}
                    </Typography>
                  </Box>
                </Card>
              );
            }
          )}
        </Box>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              height: "100%",
            }}
          >
            <Box sx={{ height: "10%", mb: 2 }} className="d-flex flex-column">
              <IconButton className="ms-auto" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
              <Divider flexItem sx={{ background: "grey" }} />
            </Box>

            <Box className=" bg-light" sx={{ width: "100%", height: "90%" }}>
              <iframe
                className="h-100 w-100"
                src={selectedItem}
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen="allowfullscreen"
                mozallowfullscreen="mozallowfullscreen"
                msallowfullscreen="msallowfullscreen"
                oallowfullscreen="oallowfullscreen"
                webkitallowfullscreen="webkitallowfullscreen"
              ></iframe>
            </Box>
          </Box>
          {/* */}
          {/* */}
        </Box>
      </Modal>
    </>
  );
}
