import * as React from "react";
import Homepage from "../pages/Homepage";
import { Route, Routes } from "react-router-dom";
import NavbarComponent from "../component/Navbar/Navbar";
import Mice from "../component/Mice/Mice";
import Expo from "../component/Expo/Expo";
import LeadCapture from "../component/LeadCapture/LeadCapture";
import ProductPage from "../pages/ProductPage";
import Aboutus from "../component/About-Us/Aboutus.js";
import ChatManagement from "../component/Chat-Management/ChatManagement.js";
import ContactUs from "../component/Contact-Us/Contactus.js";
import Footer from "../component/FooterCards/Footer";
import PhotoBooth from "../component/PhotoBooth/PhotoBooth";
import EventRegistration from "../component/EventRegistration/EventRegistration";
import PreEvent from "../component/PreEventNetworking/PreEvent";
import FileManager from "../component/FileManager/FileManager";
import WebsiteBuilder from "../component/WebsiteBuilder/WebsiteBuilder";
import PrivacyPolicy from "../component/MobileAppPrivacyPolicy/PrivacyPolicy";
export default function App() {
  return (
    <>
      <NavbarComponent />
      <Routes>
        <Route>
          {/* Frontend Ui */}
          <Route path="/" element={<Homepage />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/mice" element={<Mice />} />
          <Route path="/expo" element={<Expo />} />
          <Route path="/leadcapture" element={<LeadCapture />} />
          <Route path="/chatmanagement" element={<ChatManagement />} />
          <Route path="/photobooth" element={<PhotoBooth />} />
          <Route path="/eventregistration" element={<EventRegistration />} />
          <Route path="/pre-event" element={<PreEvent />} />
          <Route path="/filemanager" element={<FileManager />} />
          <Route
            path="/mobile-app-privacy-policy"
            element={<PrivacyPolicy />}
          />
          <Route
            path="/micro-event-site-builder"
            element={<WebsiteBuilder />}
          />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}
