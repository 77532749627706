import { Container, Typography } from "@mui/material";
import React from "react";

export default function PrivacyPolicy() {
  return (
    <Container
      maxWidth="xl"
      className="py-5"
      sx={{
        textAlign: "justify",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography
        variant="h4"
        component="h4"
        className="fw-semibold text-center mb-4"
      >
        Mobile App Privacy Policy
      </Typography>
      <Typography variant="subtitle1" component="p">
        BizConnect Events respects the privacy of its Mobile App users. We
        created this Privacy Policy to inform you of how we collect, use, share,
        and protect your personal information when you use our App.
      </Typography>
      <Typography variant="subtitle1" component="p">
        This is the privacy policy (“Policy”) that governs how we, BizConnect
        Events Ltd. and its affiliates (“BizConnect Events”, “we”, “our” or
        “us”), use and protect the Personal Information that we collect and/or
        receive about natural persons in connection with the use of our App i .e
        contact@bizconnectevents.com, its sub-domains, the web applications, and
        mobile applications (“Platform”).
      </Typography>
      <Typography variant="subtitle1" component="p">
        BizConnect Event is a virtual event hosting Platform which enables the
        hosting of events by Customer/s and connects them with Attendees/
        Participants for providing the Services through the Platform.
      </Typography>
      <Typography variant="subtitle1" component="p">
        In this document, you/your refers to the “User” and/or the “Customer”.
        “User/s” as used herein refers to any individual/s and/or entities added
        to the Platform by the Customer and/or an individual permitted by the
        Customer to register, access, and/or use the Platform; the word
        “Customer” as used herein refers to anyone, subscribing to, registering,
        accessing or using the Platform as an event organizer/administrator, who
        can add users/attendees/participants to the Platform. Please note that
        the term “you” under this Policy, shall mean the Customer and
        hosts/attendees/ participants, etc. individually and collectively when
        the context requires. This Policy applies to all the visitor/s to the
        Platform. This policy does not apply to any third-party organizations
        that collect the personal data, including through any third-party
        application or content that links to or is accessible from our Platform.
      </Typography>
      <Typography variant="subtitle1" component="p">
        Please, read the Policy carefully before using or registering or
        accessing the Platform, or availing of any Services. By visiting the
        Platform or setting up an Account on the Platform and clicking on the “I
        accept” button, you accept and agree to be bound by the terms and
        conditions of this Policy and consent to our collecting, storing,
        processing, transferring, sharing and using Your information including
        the Personal Information following this Policy. If you do not agree to
        the policy you are free to leave the platform with no obligation to us.
      </Typography>
      <Typography variant="subtitle1" component="p">
        “Personal Information” means any information about an individual/natural
        person from which that person can be identified, directly or indirectly,
        and also includes information referred to as “Personally Identifiable
        Information” or “Personal Information” under applicable data privacy
        laws, and rules or regulations. It does not include data where the
        identity has been removed or anonymous data and this Policy does not
        apply to such anonymized data or de-identified or aggregated data.
      </Typography>
      <Typography variant="subtitle1" component="p"></Typography>
      <Typography variant="subtitle1" component="p"></Typography>
    </Container>
  );
}
