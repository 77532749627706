import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Divider,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

export default function HeaderCard({
  title,
  body,
  src,
  licence,
  list,
  pricing,
}) {
  const [loading, setLoading] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 300);
  }, []);
  return (
    <>
      <Card
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: { xs: "column", sm: "row", md: "row" },
          height: "100%",
          minHeight: 227,
          boxShadow: "rgba(0, 0, 0, 0.2) 0px 2px 7px",
          position: "relative",
          alignSelf: "normal",
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: {
                xs: "100%",
                md: 290,
              },
              height: {
                xs: 150,
                md: "100%",
              },
              bgcolor: "#fff",
            }}
          >
            <Skeleton
              sx={{ bgcolor: "#fff" }}
              variant="rectangular"
              width={"100%"}
              height={"100%"}
            />
          </Box>
        ) : (
          <CardMedia
            className="p-2"
            component="img"
            sx={{
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
              objectFit: "cover",
              aspectRatio: "16/9",
              width: { xs: "100%", sm: "33%", md: "33%" },
              maxHeight: { xs: 200, md: "100%" },
            }}
            image={src}
            alt="img"
          />
        )}
        <Chip
          size="small"
          label={licence}
          sx={{
            display: licence ? "auto" : "none",
            color: "#fff",
            background: "rgba(0,0,0,0.7)",
            position: "absolute",
            top: 12,
            left: 20,
            backdropFilter: "blur(10px)",
          }}
        />
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "start" },
            gap: { xs: 1, sm: 0 },
          }}
        >
          <Typography
            variant="h6"
            className="fw-bold text-sm-start text-center mb-2"
            sx={{ lineHeight: 1.2 }}
            component="h6"
          >
            {title}
          </Typography>
          <Box className="gap-1" sx={{ display: list ? "flex" : "none" }}>
            {list.map((item, index) => {
              return (
                <Chip
                  key={index}
                  component={item.link && "a"}
                  href={item.link ? item.link : null}
                  target={item.link ? "_blank" : null}
                  size="small"
                  variant="outlined"
                  label={
                    <>
                      {item.icon ? (
                        item.icon
                      ) : (
                        <img
                          src={item.src}
                          alt="android"
                          style={{
                            width: "11px",
                            marginRight: 5,
                            marginBottom: 3,
                          }}
                        />
                      )}
                      <span>{item.item}</span>
                    </>
                  }
                  className="px-2"
                  sx={{
                    fontSize: "10px",
                    cursor: item.link || item.route ? "pointer" : "default",
                    "&:hover": {
                      backgroundColor:
                        item.link || item.route ? "#eee" : "#fff",
                    },
                  }}
                  onClick={() => (item.route ? navigate(item.route) : null)}
                />
              );
            })}
          </Box>
          <Typography
            variant="caption"
            component="div"
            className="mt-2 mb-auto d-flex flex-column align-item-center justify-content-center "
          >
            <ul className="ps-3 m-0">
              {body.map((item, index) => {
                return <li key={index}>{item}</li>;
              })}
            </ul>
            <Button
              size="small"
              className="me-auto px-3 mt-2 fw-bold mx-auto ms-md-0"
              sx={{ fontSize: "14px", textTransform: "capitalize" }}
              variant="text"
              endIcon={
                <ArrowForwardIcon
                  sx={{
                    ml: -0.5,
                    mt: 0.2,
                    fontSize: "15px !important",
                    fontWeight: "bold",
                  }}
                />
              }
              onClick={() => setOpen(true)}
            >
              Know More
            </Button>
          </Typography>
        </CardContent>
      </Card>

      <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            width: "100%",
            height: "100vh !important",
            px: { xs: 2, md: 5 },
            pt: 3,
            overflowY: "scroll",
          }}
        >
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Box className="d-flex justify-content-between">
                <IconButton onClick={() => setOpen(false)}>
                  <ArrowBackIosIcon sx={{ ml: 1, color: "#064682" }} />
                </IconButton>
                <Typography
                  variant="h5"
                  component="h5"
                  className="text-center fw-bold"
                >
                  {title}
                </Typography>
              </Box>
              <Divider flexItem sx={{ background: "#eee", my: 1 }} />
            </Grid>
            <Grid item xs={12}>
              <Container className="d-flex flex-column justify-content-center w-100 mb-5">
                <Grid container spacing={5}>
                  {pricing?.table?.map((item, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        md={pricing.table.length <= 1 ? 12 : 6}
                        className="d-flex flex-column"
                      >
                        <Card
                          sx={{
                            boxShadow: 3,
                            width: {
                              xs: "100%",
                              md: pricing.table.length <= 1 ? "50%" : "100%",
                            },
                            mx: "auto",
                            borderRadius: "7px 7px 0 0 ",
                          }}
                        >
                          <CardContent className="d-flex flex-column justify-content-center align-items-center align-items-md-start gap-1 gap-md-0">
                            <Typography
                              variant="h5"
                              component="h5"
                              className="mb-2 fw-semibold"
                              sx={{ color: "#F47437" }}
                            >
                              {item?.feature}
                            </Typography>
                            <Typography
                              variant="caption"
                              component="p"
                              className="me-3 me-md-0 mb-1"
                            >
                              {" "}
                              Starts at
                            </Typography>
                            <Typography
                              variant="h5"
                              component="div"
                              className="d-flex gap-1 align-items-center fw-bold mb-1"
                            >
                              ₹ {item?.cost}
                              <Typography
                                variant="caption"
                                component="p"
                                className="ms-1 ms-md-0"
                              >
                                {item.per}
                              </Typography>
                            </Typography>
                            <Typography
                              variant="caption"
                              component="p"
                              className="text-dark"
                            >
                              {item?.domain}
                            </Typography>
                            <Button
                              variant="text"
                              sx={{
                                display: { xs: "flex", md: "none" },
                                textTransform: "lowercase",
                              }}
                              className="bg-light"
                              endIcon={
                                <ArrowForwardIcon
                                  sx={{ fontSize: "15px !important" }}
                                />
                              }
                              onClick={() => {
                                setSelectedIndex(index);
                                if (selectedIndex === index) {
                                  setSelectedIndex(null);
                                } else {
                                  setSelectedIndex(index);
                                }
                              }}
                            >
                              show {selectedIndex === index ? "less" : "more"}
                            </Button>
                          </CardContent>
                        </Card>
                        <Box
                          sx={{
                            display: {
                              xs: selectedIndex === index ? "block" : "none",
                              md: "block",
                            },
                          }}
                        >
                          <List
                            sx={{
                              boxShadow: "0 2px 10px #3443cc33 ",
                              borderRadius: "0 0 7px 7px",
                              my: 1,
                              width: {
                                xs: "100%",
                                md: pricing.table.length <= 1 ? "50%" : "100%",
                              },
                              mx: {
                                xs: 0,
                                md: pricing.table.length <= 1 ? "auto" : 0,
                              },
                            }}
                          >
                            {item.row.map(({ item, value }, index) => {
                              return (
                                <ListItem
                                  key={index}
                                  disablePadding
                                  sx={{
                                    bgcolor:
                                      (index + 1) % 2 === 0
                                        ? "#f2f2f2"
                                        : "white",
                                  }}
                                >
                                  <ListItemButton
                                    disableRipple
                                    sx={{
                                      backgroundColor: "transparent",
                                      cursor: "default",
                                      "&:hover": {
                                        backgroundColor: "transparent",
                                      },
                                    }}
                                  >
                                    <ListItemText
                                      primary={item}
                                      className=" pe-5"
                                      sx={{
                                        wordBreak: "break-word",
                                      }}
                                    />
                                    <ListItemIcon>
                                      {value === true ? (
                                        <DoneIcon sx={{ color: "#10E40F" }} />
                                      ) : value === false ? (
                                        <CloseIcon />
                                      ) : (
                                        value
                                      )}
                                    </ListItemIcon>
                                  </ListItemButton>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Box>
                      </Grid>
                    );
                  })}
                  {pricing.para ? (
                    <Grid item xs={12}>
                      <Box>
                        <Typography
                          variant="h6"
                          component="h6"
                          className="fw-bold my-2"
                          sx={{ color: "#0b4995" }}
                        >
                          Additional Add-on Services:
                        </Typography>
                        {pricing?.para?.map(({ title, item, value }, index) => {
                          return (
                            <Typography
                              key={index}
                              variant="h6"
                              component="div"
                              className="my-1"
                            >
                              <strong>{title} : </strong>
                              {item} {""} {value}
                            </Typography>
                          );
                        })}
                      </Box>
                    </Grid>
                  ) : null}
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
}
