import ContactForm from "./ContactFunc";
import "./css/main.css";
import React from "react";

export default function ContactUs() {
  return (
    <>
      <div id="contact-page">
        <div className="row m-0 row-cols-1 row-cols-md-2 mb-5">
          <div className="col py-5 px-lg-5 px-md-4 px-4" data-aos="fade-up">
            <h1 className="heading-orange">
              BizConnect <span className="heading-blue">Events</span>
            </h1>
            <p>
              With the use of advanced and entrancing features, we design a
              virtual space, where it allows one to host and create Virtual and
              Hybrid Events, Webinars, Online Conferences, Trade Shows, Job
              Fairs, Showrooms, Exhibitions, Offices, and much more with just a
              few clicks.
            </p>
            <p>
              If you are interested in knowing more about our work, talk to us.
            </p>
            <div className="d-flex align-items-center">
              <i className="fa-solid fa-envelope me-3 fs-5 heading-orange"></i>
              <p className="lh-sm my-0">
                <strong>Email us at</strong>
                <br />
                contact@bizconnectevents.com
              </p>
            </div>
            <div className="d-flex align-items-center mt-2">
              <p className="m-0">
                <strong>Contact us on:</strong>
              </p>
              <div className="d-flex">
                <a
                  href="https://www.facebook.com/bizconnectevents.india"
                  className="fs-5 ms-2 heading-orange"
                >
                  <i className="fa-brands fa-facebook-square"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/bizconnect-pvt-ltd/mycompany/"
                  className="fs-5 ms-2 heading-orange"
                >
                  <i className="fa-brands fa-linkedin"></i>
                </a>
                <a
                  href="https://www.instagram.com/bizconnect_global_events/"
                  className="fs-5 ms-2 heading-orange"
                >
                  <i className="fa-brands fa-instagram-square"></i>
                </a>
                <a
                  href="https://twitter.com/BizConnectEven1"
                  className="fs-5 ms-2 heading-orange"
                >
                  <i className="fa-brands fa-twitter-square"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UCGj27x3_ShNbpu97zMDiYlQ"
                  className="fs-5 ms-2 heading-orange"
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="col py-4"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.02)" }}
          >
            <h2 className="heading-blue text-center" data-aos="fade-up">
              Contact Us
            </h2>
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
}
