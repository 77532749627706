import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  Skeleton,
  Stack,
  Button,
  Box,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function App({ src, title, desc, path, url }) {
  const [loading, setLoading] = React.useState(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);
  return (
    <>
      <Card
        className="shadow"
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          height: { xs: "100%", md: "12rem" },
          cursor: "pointer",
        }}
        href={url ? url : null}
        target={url ? "_blank" : null}
        onClick={() => navigate(path)}
      >
        {loading ? (
          <Box
            sx={{
              aspectRatio: "16/9",
              width: { xs: "100%", md: 200 },
            }}
          >
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
          </Box>
        ) : (
          <CardMedia
            className="p-2"
            component="img"
            sx={{
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
              objectFit: "cover",
              aspectRatio: "16/9",
              width: { xs: "100%", md: 200 },
            }}
            image={src}
            alt="img"
          />
        )}
        <CardContent className="pb-4 pb-lg-0">
          {loading ? (
            <Stack direction="column">
              <Skeleton width={"40%"} />
            </Stack>
          ) : (
            <Typography
              variant="h6"
              className="fw-semibold mb-2 lh-sm text-start textMaxLineTopNews"
              component="div"
            >
              {title}
            </Typography>
          )}
          <Typography
            variant="body2"
            color="text.secondary"
            className=" mb-auto text-start textMaxLineTopNews"
            sx={{
              fontSize: "13px",
            }}
          >
            {desc}
          </Typography>
          <Box className="d-flex w-100">
            <Button
              size="small"
              variant="text"
              href={url ? url : null}
              target={url ? "_blank" : null}
              sx={{ p: 0, ml: 0, textTransform: "lowercase", color: "#F47437" }}
              onClick={() => navigate(path)}
            >
              read more...
            </Button>
          </Box>
        </CardContent>
      </Card>
    </>
  );
}
