import React from "react";
import "./ChatManagement.css";
import contactImg from "../../../assets/contact.png";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

export default function ChatManagement() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="chatManagement">
        {/*  Header Section  */}
        <header className="container-fluid overflow-hidden px-0 position-relative">
          <div className="row h-100 align-items-center gap-4 gap-md-0 mx-0 justify-content-end justify-content-md-between">
            <div className="d-none d-md-block col-md-1 "></div>
            <div className="col-12 col-md-5 order-1 order-md-0 px-3 px-md-5">
              <h1 className="text-primary fw-bold">Biz-Chat Management</h1>
              <p>
                An all-inclusive tool that allows for better transfer of
                messages between partakers. Features include General chat, one
                on one chat between executive and attendee, Q&A, live and Polls.
              </p>
            </div>
            <div className="col-8 col-md-6 px-0 my-0 ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Chat+Management/Homepage.png"
                className="img-fluid w-100"
                alt=".."
              />
            </div>
          </div>
        </header>

        {/* Main Container */}

        <section className="main container-md my-5 py-0 py-md-5">
          {/* 1st */}
          <div className="row mx-0 my-5 gap-3 gap-md-0 ">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Chat+Management/Messaging.png"
                className="img-fluid row-img w-50 "
                alt="Event Registration and communication"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center text-center text-md-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">
                Chat with customers - One2One Chat
              </h3>
              <p>
                Booth owners, sponsors, organisers can communicate with
                customers.
              </p>
            </div>
          </div>
          {/* 2nd */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Chat+Management/Chat+with+other+attendees+in+General+chat.png"
                className="img-fluid row-img w-100"
                alt="Custom Landing Page for a Great First Impression "
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">
                Chat with other attendees in General chat
              </h3>
              <p>
                Send direct messages to other online attendees. Create multiple
                conversations based on different topics. Reduce waiting time for
                feedback and get connected.
              </p>
            </div>
          </div>
          {/* 3rd */}
          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Chat+Management/Poll+or+Survey.png"
                className="img-fluid row-img w-75"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Poll or Survey</h3>
              <p>
                provides the opportunity to hear your visitors’ voice through
                attention grabbing polls, Unlimited surveys and Entertain your
                visitors.
              </p>
            </div>
          </div>
          {/* 4th */}
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Chat+Management/Questions+and+answers.png"
                className="img-fluid row-img w-75"
                alt="Upload Documents"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Questions and answers</h3>
              <p>
                {" "}
                help you to get and share any information. Easy to embed to any
                application or websites. Generate url and embed it and get
                connected with your clients.
              </p>
            </div>
          </div>
          {/* 5th */}
          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src={contactImg}
                className="img-fluid row-img w-75"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Explore More</h3>
              <p>
                General chat, executive and attendee one-on-one chat, Q&A, live,
                and polls are just a few of the incredible features included in
                chat management.{" "}
              </p>
              <p>For more additional details relating to Chat Management</p>
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{ bgcolor: "#0b4995 !important" }}
                onClick={handleButtonClick}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
