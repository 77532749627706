import converter from "./images/converter.png";
import microsite from "./images/microsite.jpg";
import faceIndentification from "./images/face-identitication.jpg";
import virtualTour from "./images/3d-vr.png";
import leadCapture from "./images/lead-capture.png";
import websitebuilder from "./images/website-builder.webp";
import android from "./images/android.png";
import AppleIcon from "@mui/icons-material/Apple";
import LanguageIcon from "@mui/icons-material/Language";

const PriceList = [
  {
    title: "Micro Event Site",
    body: [
      "Dynamic, Responsive",
      " Pre, During & Post event connect your audience",
      "Bkend Admin access",
      "Google Analytics`",
    ],
    src: microsite,
    licence: "Valid max 60 Days",
    list: [
      {
        item: "Web App",
        icon: (
          <LanguageIcon
            sx={{
              fontSize: "13px !important",
              mr: 0.5,
              mb: 0.2,
              color: "#0b4995",
            }}
          />
        ),
        link: "https://bizconnectshows.events/",
      },
      {
        item: "Android",
        src: android,
        link: "https://play.google.com/store/apps/details?id=com.bizexpo.app.android",
      },
      {
        item: "IOS",
        icon: (
          <AppleIcon
            sx={{
              fontSize: "14px !important",
              mr: 0.5,
              mb: 0.5,
            }}
          />
        ),
      },
    ],
    pricing: {
      table: [
        {
          cost: "30,000",
          per: "/ Event",
          feature: "Standard",
          domain: "Default Domain",
          row: [
            { item: "Mode", value: "DIY" },
            { item: "No. of Attendees", value: "1000" },
            { item: "No. of Event", value: "1" },
            { item: "Event Validity", value: "60 Days" },
            {
              item: "Event Microsite Web App/Android/IOS",
              value: true,
            },
            { item: "Attendee online", value: true },
            { item: "At site Registration", value: true },
            { item: "Sponsors", value: true },
            { item: "Booths", value: true },
            { item: "Agenda", value: true },
            { item: "Event Details", value: true },
            { item: "Attendee details / profile", value: true },
            { item: "Speaker Profile", value: true },
            { item: "Venue Information ", value: true },
            { item: "Live Poll", value: true },
            { item: "Q&A", value: true },
            { item: "General chat", value: true },
            { item: "Event Floor Map", value: true },
            { item: "FAQ", value: true },
            { item: "Add to calendar", value: true },
            { item: "Event News", value: true },
            { item: "Google Analytics ", value: true },
            { item: "Physical Event Reg module", value: true },
          ],
        },
        {
          cost: "75,000",
          per: "/ Event",
          feature: "White Label",
          domain: "Client Domain",
          row: [
            { item: "Mode", value: "DIY" },
            { item: "No. of Attendees", value: "1000" },
            { item: "No. of Event", value: "1" },
            { item: "Event Validity", value: "60 Days" },
            {
              item: "Event Microsite Web App/Android/IOS",
              value: true,
            },
            { item: "Attendee online", value: true },
            { item: "At site Registration", value: true },
            { item: "Sponsors", value: true },
            { item: "Booths", value: true },
            { item: "Agenda", value: true },
            { item: "Event Details", value: true },
            { item: "Attendee details / profile", value: true },
            { item: "Speaker Profile", value: true },
            { item: "Venue Information ", value: true },
            { item: "Live Poll", value: true },
            { item: "Q&A", value: true },
            { item: "General chat", value: true },
            { item: "Event Floor Map", value: true },
            { item: "FAQ", value: true },
            { item: "Add to calendar", value: true },
            { item: "Event News", value: true },
            { item: "Google Analytics ", value: true },
            { item: "Physical Event Reg module", value: true },
          ],
        },
      ],
      para: [
        {
          title: "Support",
          item: "Client should provide the content in desired format provided by us @ rate INR.",
          value: " 10,000 / Event",
        },
        {
          title: "Custom Offer",
          item: "Custom design and change of theme",
          value: "- Talk to Sales Team",
        },
      ],
    },
  },
  {
    title: "Lead Capture",
    body: [
      "Easily scan and generate QR codes",
      "Support all types of QR codes",
    ],
    src: leadCapture,
    licence: "Valid max 60 Days",
    list: [
      {
        item: "Web App",
        icon: (
          <LanguageIcon
            sx={{
              fontSize: "13px !important",
              mr: 0.5,
              mb: 0.2,
              color: "#0b4995",
            }}
          />
        ),
        route: "/leadcapture",
      },
      {
        item: "Android",
        src: android,
        link: "https://play.google.com/store/apps/details?id=com.leadCapture.app&hl=en&gl=US",
      },
      {
        item: "IOS",
        icon: (
          <AppleIcon
            sx={{
              fontSize: "14px !important",
              mr: 0.5,
              mb: 0.5,
            }}
          />
        ),
      },
    ],
    pricing: {
      table: [
        {
          cost: "8,000",
          per: "/ Event",
          feature: "Standard",
          domain: "Default Domain",
          row: [
            { item: "Mode", value: "DIY" },
            { item: "No. of Event", value: "1" },
            { item: "Event Validity", value: "60 Days" },
            {
              item: "App - Android/IOS",
              value: true,
            },
            {
              item: "Easily scan and generate QR codes & barcodes",
              value: true,
            },
            {
              item: "Support all types of QR codes & barcode formats",
              value: true,
            },
            {
              item: "QR code and barcode decoding at a very high speed",
              value: true,
            },
            {
              item: "You can scan any code you want, the application will decode and display the scanned information to you as quickly and accurately",
              value: true,
            },
            {
              item: "Scan QR & barcodes from the images from your device",
              value: true,
            },
            { item: "Flashlight Supported", value: true },
            {
              item: "Scan history saved, easily search scan history",
              value: true,
            },
            {
              item: "Favourite data from history for easier access",
              value: true,
            },
            {
              item: "The QR Code Reader app can scan and read all BARcode types including text, URL, ISBN, contact, calendar, email, location, Wi-Fi and many other formats",
              value: true,
            },
            {
              item: "Scan barcodes and automatically open a relevant web page",
              value: true,
            },
            { item: "Generate QR or 2D Barcodes", value: true },
            {
              item: "Connect a Wifi network automatically by reading a QR code",
              value: true,
            },
            {
              item: "Generate a code from a text, link or contact using the “Create” option",
              value: true,
            },
            {
              item: "Option to automatically copy the read content to the clipboard",
              value: true,
            },
            {
              item: "Option to always launch the websites in the browser",
              value: true,
            },
            { item: "Generate coloured QR codes", value: true },
            {
              item: "Friendly and easy to use application interface",
              value: true,
            },
            {
              item: "Speed ​​reading barcode, QR codes very quickly.",
              value: true,
            },
            { item: "Dark and Light Mode", value: true },
            { item: "Multiple Languages Support", value: true },
            { item: "Provide feedback through mail", value: true },
          ],
        },
        {
          cost: "20,000",
          per: "/ Event",
          feature: "White Label",
          domain: "Client Domain",
          row: [
            { item: "Mode", value: "DIY" },
            { item: "No. of Event", value: "1" },
            { item: "Event Validity", value: "60 Days" },
            {
              item: "App - Android/IOS",
              value: true,
            },
            {
              item: "Easily scan and generate QR codes & barcodes",
              value: true,
            },
            {
              item: "Support all types of QR codes & barcode formats",
              value: true,
            },
            {
              item: "QR code and barcode decoding at a very high speed",
              value: true,
            },
            {
              item: "You can scan any code you want, the application will decode and display the scanned information to you as quickly and accurately",
              value: true,
            },
            {
              item: "Scan QR & barcodes from the images from your device",
              value: true,
            },
            { item: "Flashlight Supported", value: true },
            {
              item: "Scan history saved, easily search scan history",
              value: true,
            },
            {
              item: "Favourite data from history for easier access",
              value: true,
            },
            {
              item: "The QR Code Reader app can scan and read all BARcode types including text, URL, ISBN, contact, calendar, email, location, Wi-Fi and many other formats",
              value: true,
            },
            {
              item: "Scan barcodes and automatically open a relevant web page",
              value: true,
            },
            { item: "Generate QR or 2D Barcodes", value: true },
            {
              item: "Connect a Wifi network automatically by reading a QR code",
              value: true,
            },
            {
              item: "Generate a code from a text, link or contact using the “Create” option",
              value: true,
            },
            {
              item: "Option to automatically copy the read content to the clipboard",
              value: true,
            },
            {
              item: "Option to always launch the websites in the browser",
              value: true,
            },
            { item: "Generate coloured QR codes", value: true },
            {
              item: "Friendly and easy to use application interface",
              value: true,
            },
            {
              item: "Speed ​​reading barcode, QR codes very quickly.",
              value: true,
            },
            { item: "Dark and Light Mode", value: true },
            { item: "Multiple Languages Support", value: true },
            { item: "Provide feedback through mail", value: true },
          ],
        },
      ],
      para: [
        {
          title: "Support",
          item: "Client should provide the content in desired format provided by us @ rate INR.",
          value: "10,000 ",
        },
        {
          title: "Custom Offer",
          item: "Custom design and change of theme",
          value: "- Talk to Sales Team",
        },
      ],
    },
  },
  {
    title: "Advance Dynamic Website Builder with Backend",
    body: ["One Website", "25 GB space"],
    src: websitebuilder,
    licence: "Valid for 1 Year",
    list: [
      {
        item: "Web App",
        icon: (
          <LanguageIcon
            sx={{
              fontSize: "13px !important",
              mr: 0.5,
              mb: 0.2,
              color: "#0b4995",
            }}
          />
        ),
      },
    ],
    pricing: {
      table: [
        {
          cost: "125,000 ",
          per: "/ Website",
          feature: "White Label",
          domain: "Client Domain",
          row: [
            { item: "Mode", value: "DIY" },
            { item: "No. of Website", value: "1" },
            { item: "Custom design (one time at the beginning)", value: true },
            { item: "Event Validity", value: "1 Year" },
            {
              item: "Event Advance Dynamic Responsive website",
              value: true,
            },
            { item: "Backend access to modify content", value: true },
            { item: "Google Analytics ", value: true },
            { item: "Events & News", value: true },
            { item: "Event Registration From", value: true },
            { item: "Agenda", value: true },
            { item: "Speaker Profile", value: true },
            { item: "Social Media", value: true },
            { item: "Addition 2 pages -  Others", value: true },
            { item: "Integration to third party tools", value: true },
            { item: "Blog Module", value: true },
          ],
        },
      ],
    },
  },
  {
    title: "3D Virtual Tours with VR Enablement",
    body: [
      "Creating Virtual tours",
      "Upload panorama images",
      "Add Marker and POIs",
      "create maps, and more ..",
    ],
    src: virtualTour,
    licence: "Client Hosting Perpetual",
    list: [],
    pricing: {
      table: [
        {
          cost: "75,000",
          per: "/ VR",
          feature: "Standard",
          domain: "Default Domain",
          row: [
            { item: "Simple and Intuitive Virtual Tour ", value: true },
            { item: "VR (Webvr  - Standalone and Built-in)", value: true },
            { item: "Panorama provided by Client ", value: "Max 10 Images" },
            {
              item: "Set up by Bizconnect (2 revisions)",
              value: "1",
            },
            {
              item: "Hosting Validity in Bizconnect Server (option 1)",
              value: "90 days",
            },
            {
              item: "Client Server hosting  Build files will be provided (option 2)",
              value: "Perpetual",
            },
            {
              item: "Fast Preload of all rooms",
              value: true,
            },
            {
              item: "Image, 360 video, live stream 360, Lottie panorama",
              value: true,
            },
            {
              item: "Nav marker for move from one room to another",
              value: true,
            },
            { item: "Click anywhere mode", value: true },
            {
              item: "Pois for show image, gallery, video, video 360, audio, text, html, link, form, pdf, download file, google maps, object 360, object3d, product, callout text",
              value: true,
            },
            {
              item: "Snipcart integration for sell products directly on the tour",
              value: true,
            },
            {
              item: "Pois embedded (image, video, video with background removal, slideshow, text, link)",
              value: true,
            },
            {
              item: "Pois scheduling",
              value: true,
            },
            { item: "Multi maps with the position where you are", value: true },
            {
              item: "3d view (dollhouse)",
              value: true,
            },
            {
              item: "Measurements",
              value: true,
            },
            {
              item: "Compass to indicate north",
              value: true,
            },
            {
              item: "Controls (zoom, fullscreen, map, room list, icons, song, share)",
              value: true,
            },
            { item: "Custom logo", value: true },
            {
              item: "Nadir logo (to hide tripod)",
              value: true,
            },
            {
              item: "Auto Rotate on inactivity",
              value: true,
            },
            {
              item: "Effects (snow,rain,fog,fireworks,confetti,sparkle) - fly-in effect",
              value: true,
            },
            { item: "Transition effects", value: true },
            {
              item: "Passcode / leads form to protect rooms access",
              value: true,
            },
            { item: "Advertisements", value: true },
            { item: "Presentation", value: true },
            { item: "Multi room’s view (virtual staging)", value: true },
            { item: "Info box", value: true },
            { item: "Image gallery / slideshow video", value: true },
            { item: "Facebook messenger / whatsapp chat", value: true },
            {
              item: "Live session (shared virtual tour – video call – chat)",
              value: true,
            },
            { item: "Meeting (jitsi)", value: true },
            { item: "Webvr support", value: true },
            { item: "Voice commands support", value: true },
            { item: "Google analytics integration", value: true },
            { item: "Real time view of visitors", value: true },
            { item: "Responsive", value: true },
            { item: "Multi language support", value: true },
            { item: "Pwa compatible", value: true },
            { item: "Panorama designs", value: "Talk to Sales" },
            { item: "Obj object designs", value: "Talk to Sales" },
            { item: "Showcases", value: true },
            { item: "Fly-in", value: true },
            { item: "Preview", value: true },
            { item: "Link ", value: true },
            { item: "Embed code", value: false },
            { item: "Protect virtual tour with a password", value: true },
          ],
        },
        {
          cost: "150,000",
          per: "/ VR",
          feature: "White Label",
          domain: "Client Domain",
          row: [
            { item: "Simple and Intuitive Virtual Tour ", value: true },
            { item: "VR (Webvr  - Standalone and Built-in)", value: true },
            { item: "Panorama provided by Client ", value: "Max 10 Images" },
            {
              item: "Set up by Bizconnect (2 revisions)",
              value: "1",
            },
            {
              item: "Hosting Validity in Bizconnect Server (option 1)",
              value: "90 days",
            },
            {
              item: "Client Server hosting  Build files will be provided (option 2)",
              value: "Perpetual",
            },
            {
              item: "Fast Preload of all rooms",
              value: true,
            },
            {
              item: "Image, 360 video, live stream 360, Lottie panorama",
              value: true,
            },
            {
              item: "Nav marker for move from one room to another",
              value: true,
            },
            { item: "Click anywhere mode", value: true },
            {
              item: "Pois for show image, gallery, video, video 360, audio, text, html, link, form, pdf, download file, google maps, object 360, object3d, product, callout text",
              value: true,
            },
            {
              item: "Snipcart integration for sell products directly on the tour",
              value: true,
            },
            {
              item: "Pois embedded (image, video, video with background removal, slideshow, text, link)",
              value: true,
            },
            {
              item: "Pois scheduling",
              value: true,
            },
            { item: "Multi maps with the position where you are", value: true },
            {
              item: "3d view (dollhouse)",
              value: true,
            },
            {
              item: "Measurements",
              value: true,
            },
            {
              item: "Compass to indicate north",
              value: true,
            },
            {
              item: "Controls (zoom, fullscreen, map, room list, icons, song, share)",
              value: true,
            },
            { item: "Custom logo", value: true },
            {
              item: "Nadir logo (to hide tripod)",
              value: true,
            },
            {
              item: "Auto Rotate on inactivity",
              value: true,
            },
            {
              item: "Effects (snow,rain,fog,fireworks,confetti,sparkle) - fly-in effect",
              value: true,
            },
            { item: "Transition effects", value: true },
            {
              item: "Passcode / leads form to protect rooms access",
              value: true,
            },
            { item: "Advertisements", value: true },
            { item: "Presentation", value: true },
            { item: "Multi room’s view (virtual staging)", value: true },
            { item: "Info box", value: true },
            { item: "Image gallery / slideshow video", value: true },
            { item: "Facebook messenger / whatsapp chat", value: true },
            {
              item: "Live session (shared virtual tour – video call – chat)",
              value: true,
            },
            { item: "Meeting (jitsi)", value: true },
            { item: "Webvr support", value: true },
            { item: "Voice commands support", value: true },
            { item: "Google analytics integration", value: true },
            { item: "Real time view of visitors", value: true },
            { item: "Responsive", value: true },
            { item: "Multi language support", value: true },
            { item: "Pwa compatible", value: true },
            { item: "Panorama designs", value: "Talk to Sales" },
            { item: "Obj object designs", value: "Talk to Sales" },
            { item: "Showcases", value: true },
            { item: "Fly-in", value: true },
            { item: "Preview", value: true },
            { item: "Link ", value: true },
            { item: "Embed code", value: true },
            { item: "Protect virtual tour with a password", value: true },
          ],
        },
      ],
    },
  },
  {
    title: "Face Identification",
    body: ["Search your event photos with our Facial recognition AI tool"],
    src: faceIndentification,
    licence: "Valid 30 Days",
    list: [
      {
        item: "Web App",
        icon: (
          <LanguageIcon
            sx={{
              fontSize: "13px !important",
              mr: 0.5,
              mb: 0.2,
              color: "#0b4995",
            }}
          />
        ),
      },
    ],
    pricing: {
      table: [
        {
          cost: "10,000",
          per: "/ Event",
          feature: "Standard",
          domain: "Default Domain",
          row: [
            { item: "Mode", value: "DIY" },
            { item: "Validity", value: "30 Days" },
            {
              item: "Upload Images",
              value: true,
            },
            {
              item: "Images Search",
              value: true,
            },
            {
              item: "Share Image",
              value: true,
            },
            {
              item: "Download Images",
              value: true,
            },
          ],
        },
        {
          cost: "30,000",
          per: "/ Event",
          feature: "White Label",
          domain: "Client Domain",
          row: [
            { item: "Mode", value: "DIY" },
            { item: "Validity", value: "30 Days" },
            {
              item: "Upload Images",
              value: true,
            },
            {
              item: "Images Search",
              value: true,
            },
            {
              item: "Share Image",
              value: true,
            },
            {
              item: "Download Images",
              value: true,
            },
          ],
        },
      ],
      para: [
        {
          title: "Support",
          item: "Client should provide the content in desired format provided by us @ rate INR",
          value: "10,000 ",
        },
        {
          title: "Custom Offer",
          item: "Custom design and change of theme",
          value: "- Talk to Sales Team",
        },
      ],
    },
  },
  {
    title: "Converter Event Responsive Websites to Mobile Apps",
    body: ["Convert Event Website to a Mobile App (Android & IOS)."],
    src: converter,
    licence: "Perpetual",
    list: [
      {
        item: "Web App",
        icon: (
          <LanguageIcon
            sx={{
              fontSize: "13px !important",
              mr: 0.5,
              mb: 0.2,
              color: "#0b4995",
            }}
          />
        ),
      },
      {
        item: "Android",
        src: android,
      },
      {
        item: "IOS",
        icon: (
          <AppleIcon
            sx={{
              fontSize: "14px !important",
              mr: 0.5,
              mb: 0.5,
            }}
          />
        ),
      },
    ],
    pricing: {
      table: [
        {
          cost: "150,000",
          per: "",
          feature: "Standard",
          domain: "Default Domain",
          row: [
            { item: "Responsive website ", value: true },
            { item: "Hosting", value: "Client server" },
            {
              item: "Play store  & App Store",
              value: "Client Account",
            },
            {
              item: "Firebase ",
              value: "Client Account",
            },
            {
              item: "Support ",
              value: "3 months",
            },
            { item: "Send Push Notification", value: true },
            { item: "Admob Integration", value: true },
            { item: "Flash Screen", value: true },
            { item: "Deep link Support", value: true },
            { item: "Location Mapping", value: true },
            { item: "Social Sign-up & sign-in", value: true },
            { item: "Initial Hosting Support", value: true },
          ],
        },
      ],
      para: [
        {
          title: "Custom Offer",
          item: "Custom design and change of theme",
          value: "- Talk to Sales Team",
        },
      ],
    },
  },
];
export default PriceList;
