import React, { useState, useEffect } from "react";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import "./css/main.css";
import { useRef } from "react";

const ContactForm = () => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [city, setCity] = useState("");
  const [message, setMessage] = useState("");
  const [phoneKey, setPhoneKey] = useState(Date.now()); // Key to force re-render of phone input
  const [url, setUrl] = useState(null);

  const phoneInputRef = useRef(null); // Ref for the phone input

  const handlePhoneInputChange = (
    isValid,
    value,
    selectedCountryData,
    fullNumber,
    countryData
  ) => {
    setPhone(value);
    setCountryCode(selectedCountryData.dialCode);
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      // check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          // for when getting location is a success
          getAddress(position.coords.latitude, position.coords.longitude);
        },
        function error(error_message) {
          // for when getting location results in an error
          console.error(
            "An error has occured while retrieving location",
            error_message
          );
          ipLookUp();
        }
      );
    } else {
      // geolocation is not supported
      // get your location some other way
      console.log("geolocation is not enabled on this browser");
      ipLookUp();
    }
    // Clear form
    setFirstname("");
    setLastname("");
    setEmail("");
    setPhone("");
    setCompany("");
    setCity("");
    setMessage("");
  }, []);

  React.useEffect(() => {
    const savedValue = sessionStorage.getItem("storedUrl");
    if (savedValue) {
      setUrl(savedValue);
    } else {
      console.log("none:");
      setUrl("https://bizconnect.events/");
    }
  }, []);

  function handleSubmit(e) {
    e.preventDefault();
    // Create the raw payload marketing@bizconnectevents.com
    const payload = {
      to: "marketing@bizconnectevents.com",
      subject: `Contacted by ${firstname}`,
      text: `Name: ${firstname} ${lastname},
             Company Name: ${company},
             Email: ${email},
             Message: ${message},
             Phone: +${countryCode} ${phone}
             `,
      html: `
      <table id="customers" style="font-family: Arial, Helvetica, sans-serif;border-collapse: collapse;width: 100%;">
        <tr>
          <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;width:30%">Name</th>
          <th style="border: 1px solid #ddd;padding: 8px;padding-top: 12px;padding-bottom: 12px;text-align: left;background-color: #F87330;color: white;font-weight:bold;">Value</th>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Name</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${firstname} ${lastname}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Email</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${email}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Phone Number</td>
          <td style="border: 1px solid #ddd;padding: 8px;">+${countryCode} ${phone}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Company Name</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${company}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">City</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${city}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Message</td>
          <td style="border: 1px solid #ddd;padding: 8px;">${message}</td>
        </tr>
        <tr>
          <td style="border: 1px solid #ddd;padding: 8px;font-weight:bold;width:30%">Site</td>
          <td style="border: 1px solid #ddd;padding: 8px;"><a href=${url}>${
        url ? url.split("://")[1] : "BizConnect.Events"
      }</a></td>
        </tr>
      </table>
      `,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
      redirect: "follow",
    };

    fetch(
      "https://asia-south1-biz-contact-form-8001a.cloudfunctions.net/sendMail",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.log("error", error));

    // Show alert
    document.querySelector(".alert").style.display = "block";

    // Hide alert after 3 seconds
    setTimeout(function () {
      document.querySelector(".alert").style.display = "none";
    }, 3000);

    // Clear the phone input value
    setPhoneKey(Date.now());

    // Clear form
    setFirstname("");
    setLastname("");
    setEmail("");
    setCompany("");
    setCity("");
    setMessage("");
    setPhone("");
  }

  function ipLookUp() {
    fetch("http://ip-api.com/json")
      .then(function (response) {
        if (!response.ok) {
          throw new Error(
            "Request failed. Returned status of " + response.status
          );
        }
        return response.json();
      })
      .then(function (data) {
        getAddress(data.lat, data.lon);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function getAddress(latitude, longitude) {
    fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`
    )
      .then((response) => response.json())
      .then((data) => {
        setCity(data.city);
      })
      .catch((error) => console.log("Request failed. Returned error:", error));
  }

  return (
    <div>
      <p className="alert">
        <i className="fa-solid fa-circle-check"></i> Submitted
      </p>
      <form
        className="row g-3 mt-4 px-md-5"
        data-aos="fade-up"
        id="contact-form"
        onSubmit={handleSubmit}
      >
        <div className="col-md-6">
          <input
            type="text"
            className="form-control shadow-sm border-0 py-2"
            id="firstname"
            placeholder="First Name*"
            required
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </div>
        <div className="col-md-6">
          <input
            type="text"
            className="form-control shadow-sm border-0 py-2"
            id="lastname"
            placeholder="Last Name"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </div>
        <div className="col-12">
          <input
            type="email"
            className="form-control shadow-sm border-0 py-2"
            id="email"
            placeholder="Email Address*"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="col-12">
          <IntlTelInput
            type="tel"
            key={phoneKey} // Key to force re-render
            containerClassName="intl-tel-input"
            inputClassName="form-control shadow-sm border-0 py-2"
            preferredCountries={["in"]}
            separateDialCode
            placeholder=""
            onPhoneNumberChange={handlePhoneInputChange}
            inputRef={phoneInputRef} // Assign the ref to the inputRef prop
          />
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control shadow-sm border-0 py-2"
            id="companyName"
            placeholder="Company Name*"
            required
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </div>
        <div className="col-12">
          <input
            type="text"
            className="form-control shadow-sm border-0 py-2"
            id="city"
            placeholder="Your City"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
        <div className="col-12">
          <textarea
            className="form-control shadow-sm border-0 py-2"
            placeholder="Message*"
            id="message"
            rows="5"
            required
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
        </div>

        <div className="col-12">
          <button
            type="submit"
            className="btn btn-primary d-block w-100 btn-orange shadow-sm border-0 fs-5 fw-bold"
          >
            SEND
          </button>
        </div>
      </form>

      {/* Your alert component */}
    </div>
  );
};

export default ContactForm;
