import React from "react";
import "./FileManager.css";
import contactImg from "../../../assets/contact.png";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

export default function FileManager() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="FileManager" className="m-0 p-0">
        <header className="container-fluid overflow-hidden px-0 ">
          <div className="row h-100 align-items-center gap-4 gap-md-0 mx-0 justify-content-end justify-content-md-between">
            <div className="d-none d-md-block col-md-1 "></div>
            <div className="col-12 col-md-5 order-1 order-md-0 px-3 px-md-5">
              <h1 className="text-primary fw-bold ">File Manager</h1>
              <p>
                Access from any device, manage users and general settings from
                an intuitive administration panel, customise it to your brand.
              </p>
              <p>
                Simple and flexible plug-and-play file manager, easy to use and
                plenty of options. Send files to your customers, create new
                users with dedicated folders, or simply use it as your personal
                file cloud.
              </p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>No
                  DataBase needed
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>No
                  coding skills required
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Supports any language (including Right-to-Left option)
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Easy
                  customization
                </li>
              </ul>
            </div>
            <div className="col-8 col-md-6 px-0 my-0 ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/header.png"
                className="img-fluid w-100"
                alt="..."
              />
            </div>
          </div>
        </header>

        {/* <!-- Main Container --> */}

        <section className="main container-md my-5 py-0 py-md-5">
          <div className="row mx-0 my-5 gap-3 gap-md-0 ">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/Easy+customization.png"
                className="img-fluid row-img"
                alt="Event Registration and communication"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center text-center text-md-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">Easy customization</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Folder
                  tree navigation
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Rename
                  Files and Folders
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Sortable items by name, date and size
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Pagination
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Download Folde
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Group
                  actions
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Download multiple files as .zip archives
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Delete
                  files and Folders
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Move
                  files through directories
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Copy
                  files through directories
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Server
                  side processed lists via Ajax
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Multilanguage (20+ included)
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>RTL
                  support
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Translations editor
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Custom
                  E-Mail HTML templates
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/Work+from+home.png"
                className="img-fluid row-img"
                alt="Custom Landing Page for a Great First Impression "
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">USERS</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Multiple roles with custom permissions
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Assign
                  one or more specific directories per user
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Optional Max available space per user
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>User
                  Sign Up (optional)
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>User
                  Panel
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Profile
                  picture & default avatars
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Password recovery
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Upload
                  notifications between users
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>E-mail
                  notifications to new users
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/upload.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">UPLOAD</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Upload
                  BIG files
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Remote
                  uploader
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Pause /
                  Resume
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Drag &
                  drop uploader
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Multiple file uploading
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Upload
                  progress
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/sharing.png"
                className="img-fluid row-img"
                alt="Upload Documents"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center">
              <h3 className="text-primary fw-bold">FILE SHARING</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>e-Mail
                  Form
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Link
                  expiration time
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Optional password protected links
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/Upload+video.png"
                className="img-fluid row-img"
                alt="In-Event & Virtual Attendee Networking"
              />
            </div>
            <div className="col-12 col-md-6 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">MEDIA</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Quick
                  image preview
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Inline
                  thumbnails
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Audio
                  player
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Video
                  player
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 py-md-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-end order-md-1 text-center">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/Search.png"
                className="img-fluid row-img"
                alt="Meeting Scheduler"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center flex-column justify-content-center">
              <h3 className="text-primary fw-bold">SEARCH</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Global
                  search
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Quick
                  search module
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Archive
                  map navigation
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/Data+security+(1).png"
                className="img-fluid row-img"
                alt="Profile Search  (Match)"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-start">
              <h3 className="text-primary fw-bold">SECURITY</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>IP
                  blacklist / whitelist
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Optional CAPTCHA security code for login and shared links
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Google
                  reCAPTCHA
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>
                  Hotlinks prevented{" "}
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-5 text-center text-md-end order-md-1">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/File+Manager/Analytics.png"
                className="img-fluid row-img"
                alt="Post-Event Analytics "
              />
            </div>
            <div className="col-12 col-md-4 d-flex align-items-start flex-column justify-content-center">
              <h3 className="text-primary fw-bold">STATISTICS</h3>
              <ul style={{ listStyle: "none" }}>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Daily /
                  Weekly / Monthly reports
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Trend
                  charts for date ranges
                </li>
                <li>
                  <i className="fa-solid fa-check text-success pe-2"></i>Export
                  logs as .csv
                </li>
              </ul>
            </div>
          </div>

          <div className="row mx-0 my-5 gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center mx-auto">
              <img
                src={contactImg}
                className="img-fluid row-img"
                alt="contact us"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-start">
              <h3 className="text-primary fw-bold">Information</h3>
              <p>
                Join a group to meet people, make friends, find support, grow a
                business, and explore your interests. Thousands of events are
                happening every day, both online and in person!
              </p>
              <p>For more additional details relating to File Manager </p>
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  bgcolor: "#0b4995 !important",
                  ml: 0,
                  mr: "auto",
                }}
                onClick={handleButtonClick}
              >
                Contact Us
              </Button>
            </div>
          </div>
        </section>
        {/* <section>
          <div className="row mx-0 mt-5 py-0 py-md-5 gap-3 gap-md-0 justify-content-center d-none">
            <div className="col-12 col-md-5 d-flex justify-content-center flex-column order-1 order-md-0">
              <h3 className="text-primary fw-bold">Explore More</h3>
              <p>
                Join a group to meet people, make friends, find support, grow a
                business, and explore your interests. Thousands of events are
                happening every day, both online and in person!
              </p>
              <button
                className="btn btn-primary px-5 align-self-start"
                data-bs-target="#carouselExampleInterval"
                data-bs-slide="next"
              >
                Next
              </button>
            </div>
            <div className="col-12 col-md-6 tablet overflow-hidden ">
              <div className="tablet-body">
                <div
                  id="carouselExampleInterval"
                  className="carousel slide h-100"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner rounded h-100">
                    <div
                      className="carousel-item active h-100"
                      data-bs-interval="10000"
                    >
                      <img
                        src="../src/img/PreEventImg/1.png"
                        className="d-block img-fluid"
                        style={{ objectFit: "cover" }}
                        alt="..."
                      />
                    </div>
                    <div
                      className="carousel-item h-100"
                      data-bs-interval="2000"
                    >
                      <img
                        src="../src/img/PreEventImg/2.png"
                        className="d-block img-fluid"
                        style={{ objectFit: "cover" }}
                        alt="..."
                      />
                    </div>
                    <div className="carousel-item h-100">
                      <img
                        src="../src/img/PreEventImg/3.png"
                        className="d-block img-fluid"
                        style={{ objectFit: "cover" }}
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </div>
    </>
  );
}
