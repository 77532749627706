import React from "react";
import "./PhotoBooth.css";
// import Carousel from "react-bootstrap/Carousel";
import contactImg from "../../../assets/contact.png";
import { Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";
export default function PhotoBooth() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="PhotoBooth">
        {/* Header Section  */}
        <header className="container-fluid overflow-hidden px-0 position-relative">
          <div className="row h-100 align-items-center gap-4 gap-md-0 mx-0 justify-content-end justify-content-md-between">
            <div className="d-none d-md-block col-md-1 "></div>
            <div className="col-12 col-md-5 order-1 order-md-0 px-3 px-md-5">
              <h1 className="text-primary fw-bold">Photo booth</h1>
              <p>
                Remember all the important times with the photo booth feature
                that curates your best moments and puts them on a show with
                beautiful-looking themes.
              </p>
            </div>
            <div className="col-8 col-md-6 px-0 my-0 text-end">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/PhotoBooth/header.png"
                className="img-fluid w-100"
                alt="..."
              />
            </div>
          </div>
        </header>

        {/* Main Container  */}

        <section className="main container-md my-5 py-0 py-md-5">
          <div className="row mx-0">
            <div className="col-12">
              <h4 className="text-primary fw-bold">
                It's a customizable, online photo booth, which means it doesn't
                require any download and can be used by anyone, anywhere.
                Virtual photo booths are the perfect solution for marketing
                campaigns, sporting events, live entertainment, and of course
                in-person, hybrid, and online events.
              </h4>
              <ul style={{ listStyle: "none" }}>
                <li>- Magic Backgrounds</li>
                <li>- Overlays</li>
                <li>- Stickers</li>
                <li>
                  - Know your audience. Event veterans know the importance of
                  getting to know your attendees before the event even begins.
                </li>
                <li>- Know what to expect.</li>
                <li>- Ask the right questions.</li>
                <li>- Change your approach with different attendees.</li>
                <li>- Add value with your follow-up.</li>
                <li>- Get creative.</li>
              </ul>
              <h4 className="text-primary fw-bold">
                Virtual booths and marketing
              </h4>
              <ul style={{ listStyle: "none" }}>
                <li>- Tell attendees about your booth in advance.</li>
                <li>- Offer a handouts or a lead generation products.</li>
                <li>- Grow your email list.</li>
                <li>- Engage with your audience.</li>
                <li>- Make it easy for attendees to buy your products.</li>
              </ul>
            </div>
          </div>
          <div className="row mx-0 my-5 justify-content-center gap-3 gap-md-0">
            <div className="col-8 col-md-6 text-center text-md-end order-md-1">
              <img
                src={contactImg}
                className="img-fluid row-img"
                alt="contact us"
              />
            </div>
            <div className="col-12 col-md-5 d-flex align-items-md-start flex-column justify-content-center text-center text-md-start">
              <h3 className="text-primary fw-bold">Explore More</h3>
              <p>
                Putting the fun in your photos! Creating memories one flash at a
                time! Putting the fun into your function!
              </p>
              <p>For more additional details relating to Photo Booth</p>
              <Button
                size="small"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                sx={{
                  bgcolor: "#0b4995 !important",
                  mx: { xs: "auto" },
                  ml: { sm: 0 },
                }}
                onClick={handleButtonClick}
              >
                Contact Us
              </Button>
            </div>
          </div>
          {/* Explore More  */}
          {/* <div className="row mx-0 mt-5 py-0 py-md-5 gap-3 gap-md-0 justify-content-center ">
            <div className="col-12 col-md-5 d-flex justify-content-center flex-column order-1 order-md-0">
              <h3 className="text-primary fw-bold">Explore More</h3>
              <p>
                Putting the fun in your photos! Creating memories one flash at a
                time! Putting the fun into your function!
              </p>
            </div>
            <div className="col-12 col-md-6 tablet overflow-hidden">
              <div className="tablet-body">
                <Carousel controls={false} interval={2500} indicators={false}>
                  <Carousel.Item>
                    <img
                      src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/PhotoBooth/1487x1000Photo+Frame+2.png"
                      className="d-block img-fluid "
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/PhotoBooth/Photobooth+(1).png"
                      className="d-block img-fluid  "
                      style={{ objectFit: "cover" }}
                      alt="..."
                    />
                  </Carousel.Item>
                  <Carousel.Item>
                    <img
                      src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/PhotoBooth/PHOTOBOOTH.png"
                      className="d-block img-fluid "
                      style={{ objectFit: "contain" }}
                      alt="..."
                    />
                  </Carousel.Item>
                </Carousel>
              </div>
            </div>
          </div> */}
        </section>
      </div>
    </>
  );
}
