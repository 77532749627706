import * as React from "react";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import { Box } from "@mui/system";
import Card from "./Card";
import { Skeleton, Stack } from "@mui/material";

const Data = [
  {
    id: 1,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture13.png",
    title: "Event microsite which doubles in as web app",
    desc: "The Microsite is totally SaaS enabled to ensure your tasks are handled in a seamless way, pretty effortlessly. You can operate either on your laptop or on your smart phone with consummate ease. Now that's a boon isn't it!",
    link: "https://blog.bizconnect.events/The%20Seamless%20SaaS-Enabled%20Solution%20That%20Doubles%20as%20a%20Web%20App+4hFN8RsIRfQuNjQusx2E",
  },
  {
    id: 2,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture17.jpg",
    title: "Pop Up Promotions",
    desc: "Armed with promotional pop ups in the microsite/web app, your potential customers get the most out of their visits by sharing relevant information that helps them decide or buy or visit a particular stall in just a click!",
    link: "https://blog.bizconnect.events/Boost%20Sales%20with%20Bizconnect's%20App%20Promo%20Pop-Ups%20+G6M0E4xU8kmG6X1FT0bj",
  },
  {
    id: 3,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/102.jpg",
    title: "Attendee at site /online registration",
    desc: "This is a convenient option for all the attendees/delegates to register either online (prior) so they can plan all their stall visits in advance & timely manner OR atsite for the busy attendees who are busy to register online or make spur of the moment decision to attend ! This way it is convenient for both types of audience!",
    link: "https://blog.bizconnect.events/Business%20Solutions:%20Effortlessly%20Register%20and%20Create%20Your%20Profile%20on%20BizConnect's%20Exhibition%20App+eYaiEFKbSvsBeJiZScoq",
  },
  {
    id: 4,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture19.jpg",
    title: "Feedback Forms",
    desc: "The all important feedback forms which are very necessary to address any anomaly or drawback or even a suggestion of improvisation to better the user experience (or address any accidentally sidelined but very necessary course correction for over all improvement ) comes included in the web app",
    link: "https://blog.bizconnect.events/Feedback%20Forms:%20Essential%20to%20improvise%20User%20Experience%20and%20Driving%20Improvement+NoSCZCb40zewoGU7mDVH",
  },
  {
    id: 5,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture20.jpg",
    title: "Attendee/ Speakers/ Exhibitors Profile with Social media",
    desc: "The all important social media profiles of all stakeholders .. viz. Delegate/speakers/exhibitors to touch base and network post events are enabled in the app which is very necessary!",
    link: "https://blog.bizconnect.events/Speaker%20profiles%20on%20BizConnect%20App%20-%20To%20Discover%20insightful%20Strategies+kGs4F7c8pLnS39BQ2NCQ",
  },
  {
    id: 6,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture30.jpg",
    title: "Day wise detailed Agendas, Helpdesk with FAQs",
    desc: "Agendas are one of the very important aspects of a MICE or EXPO exercise because they set the tone for the various subject matters are of prine focus on which the EXPO or MICE activity is based! The App leaves no stone unturned in detailing this aspect to perfection !",
    link: "https://blog.bizconnect.events/%22Stay%20Organized%20on-the-go%20with%20Bizconnect's%20Downloadable%20Agendas!%22+eKUNLctbS0wfwbiDQh27",
  },
  {
    id: 7,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture22.jpg",
    title: "Networking 1-2-1 or Groups",
    desc: "Networking is pretty integral to any EXPO or MICE meet since till the dialogue happens between the end user & the product or service in question, nothing moves forward. We understand this important catalyst and have provided ample room for the same so proper interaction happens either as 1 on 1 OR as a group !",
    link: "https://blog.bizconnect.events/Connect,%20Collaborate%20and%20Network%20with%20Industry%20Leaders:%20Easy%20Networking%20&%20Chatting%20on%20BizConnect's%20APP+wSfA9VwT58KyI7GVX5Ki",
  },
  {
    id: 8,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture23.jpg",
    title: "Live Polls",
    desc: "Live polls are an absolute necessity to engage the audience in a meaningful way during any MICE meet or an EXPO or a Conference to involve your participants — whether you are online or in-between. Our App does the same effectively",
    link: "https://blog.bizconnect.events/Engage%20Your%20Audience%20in%20MICE%20Meets,%20Expos%20and%20Conferences%20through%20BizConnect+FHvJ1OXWu24gwKOB8c0A",
  },
  {
    id: 9,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture26.jpg",
    title: "Video & Photo Gallery & Leaderboard Area",
    desc: "Video & Photo galleries are an important part od any MICE meet or an EXPO or a Conference since they are three dimensional witness of these activities which are STORED for reference in future or a memorabilia. So is the leaderboard which is a visual representation of how participants rank, with those who rank highest at the top. Our App does a good job of this!",
    link: "https://blog.bizconnect.events/Video%20&%20Photo%20Gallery%20&%20Leader%20board%20area%20-%20%20A%203%20dimensional%20perspective%20to%20activities+l4NnlsvDvAnykwHGUYMC",
  },
  {
    id: 10,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/images/Picture25.jpg",
    title: "Reports & Analytics for Organizers& Much more",
    desc: "For any event or an Expo to perform well with enhanced efficiency, RICH event analytics and reports are an absolute necessity. As event platforms offer a wide range of features, different kinds of data are collected during the Event/EXPO. These relevant data metrics can be generated as a report in our APP!",
    link: "https://blog.bizconnect.events/Report%20&%20analytics%20for%20organizer%20%E2%80%93%20The%20birds%20eye%20view%20to%20harness%20&%20enhance%20outcome,%20the%20right%20way+tLBpTD8qJnVtaBT0Yh08",
  },
];

export default function App() {
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <>
      <Box id="MySwiper">
        <Swiper
          id="NewsPortalSwiper"
          loop={true}
          centeredSlides={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper my-0 my-md-3"
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1440: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
          }}
        >
          {Data?.map(({ title, src, desc, link }, index) => (
            <SwiperSlide controls={true} key={index}>
              {loading ? (
                <Stack spacing={1} width="100%" height="100%">
                  <Skeleton variant="rectangular" height={194} />
                  <Skeleton />
                  <Skeleton />
                  <Skeleton width="60%" />
                </Stack>
              ) : (
                <Box
                  component={link ? "a" : "div"}
                  href={link ? link : null}
                  target={link ? "_blank" : null}
                  sx={{
                    textDecoration: "none",
                    color: "inherit",
                    cursor: link ? "pointer" : "normal",
                  }}
                >
                  <Card src={src} title={title} desc={desc} />
                </Box>
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>{" "}
    </>
  );
}
