import { Box, Typography, Button } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";

const socialLinks = [
  {
    icon: <FacebookIcon sx={{ fontSize: "20px" }} />,
    link: "https://www.facebook.com/bizconnectevents.india",
  },
  {
    icon: <LinkedInIcon sx={{ fontSize: "20px" }} />,
    link: "https://www.linkedin.com/company/bizconnect-pvt-ltd/mycompany/",
  },
  {
    icon: <InstagramIcon sx={{ fontSize: "20px" }} />,
    link: "https://www.instagram.com/bizconnect_global_events/",
  },
  {
    icon: <TwitterIcon sx={{ fontSize: "20px" }} />,
    link: "https://twitter.com/BizConnectEven1",
  },
  {
    icon: <YouTubeIcon sx={{ fontSize: "19px" }} />,
    link: "https://www.youtube.com/channel/UCGj27x3_ShNbpu97zMDiYlQ",
  },
];

const btnHover = {
  "&:hover": {
    textDecoration: "underline",
  },
};

export default function Footer() {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          pt: 5,
          px: 2,
          pb: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          backgroundColor: "#0b4995",
        }}
      >
        <Typography
          variant="h5"
          component="h5"
          sx={{
            color: "#f87330",
            fontWeight: "bold",
            mb: 1,
          }}
        >
          Biz Products
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: {
              xs: 1,
              md: 3,
            },
          }}
        >
          <Button
            variant="link"
            className="p-0 text-nowrap text-white"
            onClick={() => {
              navigate("/aboutus");
              window.scrollTo({
                top: 0,
                left: 0,
                type: "smooth",
              });
            }}
            sx={btnHover}
          >
            About Us
          </Button>
          <Button
            variant="link"
            className=" p-0 text-nowrap text-white "
            href="https://virtual.bizconnect.space/terms.html"
            target="_blank"
            sx={btnHover}
          >
            Terms & Conditions
          </Button>
          <Button
            variant="link"
            className=" p-0 text-nowrap text-white"
            href="https://virtual.bizconnect.space/privacypolicy.html"
            target="_blank"
            sx={btnHover}
          >
            Privacy Policy
          </Button>
          <Button
            variant="link"
            className=" p-0 text-nowrap text-white"
            href="https://blog.bizconnect.events/"
            target="_blank"
            sx={btnHover}
          >
            Blogs
          </Button>
        </Box>
        <Box className="mb-3 mt-2 d-flex  flex-wrap align-items-start align-items-md-center gap-2">
          {socialLinks?.map(({ icon, link }, index) => {
            return (
              <Typography
                key={index}
                component="a"
                variant="link"
                target="_blank"
                href={link}
                sx={{
                  p: 0,
                  m: 0,
                  color: "#fff",
                  "&:hover": {
                    color: "#e2e2e29e !important",
                  },
                }}
              >
                {icon}
              </Typography>
            );
          })}
        </Box>
        <Typography
          variant="subtitle1"
          component="div"
          sx={{
            fontSize: "13px",
            color: "#e2e2e29e",
          }}
        >
          Copyright @2023 Bizconnect
        </Typography>
      </Box>
    </>
  );
}
