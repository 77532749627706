import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./style.css";
// import required modules
import { Pagination, Navigation, Autoplay } from "swiper";
import { motion } from "framer-motion";
import CardComponent from "./Card";
const array = [
  {
    id: 1,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/Products/img5.png",
    title: "Mice App",
    desc: "Biz Connect's all encompassing, compact Pre Event Application that gives a power packed Microsite that also doubles in as an easy & tidy Web App in your phone, on your palm! ALL in one power packed, seamlessly integrated Pre Event App on SaaS platform to exceed your expectations with effortless ease & clock work precision!",
    path: "/mice",
  },
  {
    id: 2,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/Products/img4.png",
    title: "Expo App",
    desc: "BizConnect's fully integrated One APP for all your EXPO/Exhibition needs runs on SaaS base and gives you everything on your tips in your palm on your phone or laptop to attend to all your needs with consummate ease, while leaving no stone unturned & on your command, 24x7!  With this power packed platform, managing the EXPO MAP yourself is as simple as uploading an Instagram post!",
    path: "/expo",
  },
  {
    id: 3,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/Products/img1.jpg",
    title: "Lead Captures",
    desc: "Event lead capture process has 3 steps. Planning before the event. Execution during the event & Follow-up after the event. BizConnect's App is ADEPT in all these situations to capture LEADS, depending on the kind of event it is (as in MICE meets or EXPO/Exhibition or Conferences)",
    path: "/leadcapture",
  },
  {
    id: 4,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/Products/img2.svg",
    title: "Chat Management",
    desc: "Chat Managing is an important task for any event - to keep event related transcripts, Managing Live chat in seamless manner, Managing chatbot to create PPC conversational landing page, user credential verification via OTP and things like that....which our can handle ADEPTLY !",
    path: "/chatmanagement",
  },
  {
    id: 5,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/assets/Products/img3.svg",
    title: "Virtual Event Platform",
    desc: "Virtual event platforms include a variety of tools to plan, promote and execute online events. These products are designed to help businesses deliver the same feel and value of in-person events through digital experiences. As a result, virtual event platforms are flexible software solutions that can be used to manage and host different types of online events, BizConnect has immense experience in this line to deliver you top of the line solutions!",
    url: "https://virtual.bizconnect.space/",
    path: null,
  },
];

export default function App() {
  const reveal = {
    hiddenVariant: { y: 50, opacity: 0 },
    revealedVariant: {
      y: 0,
      opacity: 1,
    },
  };
  return (
    <>
      <div id="MySwiper">
        <Swiper
          id="NewsPortalSwiper"
          slidesPerView={2}
          spaceBetween={30}
          loop={true}
          centeredSlides={true}
          loopFillGroupWithBlank={true}
          pagination={{
            clickable: true,
            dynamicBullets: true,
          }}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          navigation={true}
          modules={[Pagination, Navigation, Autoplay]}
          className="mySwiper my-0 my-md-3 py-3 pt-5"
          breakpoints={{
            300: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            640: {
              slidesPerView: 1,
              spaceBetween: 5,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
          }}
        >
          <motion.div
            variants={reveal}
            initial="hiddenVariant"
            animate="revealedVariant"
            transition={{
              ease: "easeIn",
              type: "tween",
              staggerChildren: 0.1,
              duration: 0.5,
              delayChildren: 0.5,
            }}
          >
            {array?.map(({ title, src, desc, path, url }, index) => (
              <motion.div
                initial={{ y: -50 }}
                animate={{ y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <SwiperSlide controls={true} key={title + index}>
                  <CardComponent
                    src={src}
                    title={title}
                    desc={desc}
                    path={path}
                    url={url}
                  />
                </SwiperSlide>
              </motion.div>
            ))}
          </motion.div>
        </Swiper>
      </div>
    </>
  );
}
