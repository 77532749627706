import React from "react";
import "./Aboutus.css";
import CountUp from "react-countup";
export default function Aboutus() {
  return (
    <>
      <div id="about-page">
        <section className="m-0 p-0 aboutus-page">
          <div className="aboutUs-header d-flex align-items-center px-5">
            <h1 className="text-white" data-aos="fade-up">
              Fasten Your Seat Belt <br /> With the Leading Event Company
            </h1>
          </div>

          {/* <!-- Feature section --> */}
          <div
            className="row row-cols-1 row-cols-md-2 text-center p-lg-2 mx-0"
            data-aos="fade-up"
          >
            <div className="col my-3 my-md-5 position-relative">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Webinar.webp"
                alt="..."
                className="img-fluid px-md-5 px-lg-5"
              />
              <div className="container pt-3 pb-2">
                <h4 className="heading-orange">Webinar</h4>
                <p>
                  Explore our simple yet cost-effective platform that helps you
                  effectively to move your physical meetings and conferences to
                  a virtual platform. Attendees and speakers can conveniently
                  join in from any part of the world to host or attend the
                  webinar in real-time, as well as opt for recorded sessions if
                  required. At BizConnect, we provide you with a customised
                  virtual and hybrid platform to host your webinars with ease,
                  giving you the facility of unlimited streaming, live polls,
                  Q&A sessions, and real-time interaction with attendees at your
                  fingertips.
                </p>
              </div>
              <a
                href="https://virtual.bizconnect.space/webinarevent.html"
                target="_blank"
                rel="noreferrer"
                className="btn btn-blue"
              >
                READ MORE
              </a>
            </div>
            <div className="col my-2 my-md-5 position-relative">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Virtual-%26-Hybrid-Events.webp"
                alt="..."
                className="img-fluid px-md-5 px-lg-5"
              />
              <div className="container pt-3 pb-2 ">
                <h4 className="heading-orange">Virtual & Hybrid Events</h4>
                <p>
                  BizConnect is a leading virtual and hybrid event platform that
                  strives to make your events more convenient and give you an
                  immersive experience. Our personalised multimedia experience
                  has several fantastic features like AI-based Match Making,
                  Multi-Channel Communication, B2B meeting space and platforms,
                  DIY booth builder, advanced chat features, and more.{" "}
                </p>
              </div>
              <a
                href="https://virtual.bizconnect.space/virtualevent.html"
                target="_blank"
                rel="noreferrer"
                className="btn btn-blue aboutUs-feature"
              >
                READ MORE
              </a>
            </div>
            <div className="col my-3 my-md-5 position-relative ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Virtual-Experience-Center.webp"
                alt="..."
                className="img-fluid px-5"
              />
              <div className="container pt-3 pb-2 ">
                <h4 className="heading-orange">Virtual Experience Center</h4>
                <p>
                  BizConnect not only deals in digitizing spaces in 3D and
                  360°views of your existing physical area but also in creating
                  the virtual spaces that lack any existing area in the most
                  customized manner with graphics to captivate your audience in
                  a more realistic pattern.
                </p>
              </div>
              <a
                href="https://bizconnect.space/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-blue aboutUs-feature"
              >
                READ MORE
              </a>
            </div>
            <div className="col my-2 my-md-5 position-relative ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Physical-Events.webp"
                alt="..."
                className="img-fluid px-5"
              />
              <div className="container pt-3 pb-2 pb-md-5 ">
                <h4 className="heading-orange">Physical Events</h4>
                <p>
                  BizConnect expertise to design and organise events for both
                  in-person and virtual attendees from all over the world that
                  captivates them in a collective event environment. We provide
                  you with a seamless and superbly convenient medium for hosting
                  any physical events such as Off-Site Tour, Exhibitions, and
                  NRI Events.
                </p>
              </div>
              <a
                href="https://bizconnectevents.com/"
                target="_blank"
                rel="noreferrer"
                className="btn btn-blue aboutUs-feature"
              >
                READ MORE
              </a>
            </div>
          </div>

          {/* <!-- What benchmark has Bizconnect set ? --> */}
          <h4
            className="w-100 py-3 mt-3 text-white text-center"
            style={{ backgroundColor: "#0B4995" }}
          >
            What benchmark has Bizconnect set?
          </h4>
          <p className="text-center fw-bold fs-5">
            Create unforgettable event experiences
          </p>

          <div className="container" data-aos="fade-up">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center  my-4 mx-0">
              <div
                className="col text-center position-relative m-2"
                style={{ width: "240px" }}
              >
                <img
                  src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/BenchMark.webp"
                  alt="..."
                  className="img-fluid"
                />
                <p className="position-absolute top-50 start-50 translate-middle text-white">
                  Reduce the cost by paying what you choose!
                </p>
              </div>
              <div
                className="col text-center position-relative m-2"
                style={{ width: "240px" }}
              >
                <img
                  src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/BenchMark.webp"
                  alt="..."
                  className="img-fluid"
                />
                <p className="position-absolute top-50 start-50 translate-middle text-white">
                  Increase attendee and exhibitor value
                </p>
              </div>
              <div
                className="col text-center position-relative m-2"
                style={{ width: "240px" }}
              >
                <img
                  src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/BenchMark.webp"
                  alt="..."
                  className="img-fluid"
                />
                <p className="position-absolute top-50 start-50 translate-middle text-white">
                  With only a few clicks, you can personalize your event
                </p>
              </div>
              <div
                className="col text-center position-relative m-2"
                style={{ width: "240px" }}
              >
                <img
                  src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/BenchMark.webp"
                  alt="..."
                  className="img-fluid"
                />
                <p className="position-absolute top-50 start-50 translate-middle text-white">
                  Select from a variety of best-in-className features
                </p>
              </div>
            </div>
          </div>

          {/* <!-- About Us Section --> */}
          <section className="position-relative " id="aboutSection">
            <div className="introduction-circle d-none d-md-none d-lg-block"></div>
            <h2 className="heading-orange text-center text-lg-start ps-lg-4">
              About Us :
            </h2>
            <div
              className="container-fluid rounded about-section py-2"
              data-aos="fade-up"
            >
              <div className="row row-cols-1 row-cols-lg-2 mx-0 pt-4">
                <div className="col order-1 order-lg-0">
                  <p className="paragraph-justify">
                    Our journey began in 2013 when we would provide experiential
                    branding services to our clients through the form of
                    exhibitions, events, retail, and other services. As our team
                    and resources grew exponentially over the years, we began
                    exploring the digital and virtual world, where we embarked
                    upon our journey to design and explore virtual environments.
                    With the help of our expert team, we soon began specialising
                    in transforming physical events and environments into a
                    virtual platform by using advanced immersive technologies,
                    giving our clients and their audiences a chance to explore
                    customised Virtual Events, Exhibitions, Webinars, Hybrid
                    Events, Showrooms, Offices, and more.
                  </p>
                </div>
                <div className="col text-center">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/aboutUs-Img.webp"
                    alt="..."
                    className="img-fluid"
                  />
                </div>
              </div>
              <p className="px-2 paragraph-justify mt-0">
                We are proud to state that the skills and the experiences we
                offer on our platform have been valued and tested by over 35+
                countries of the World. We have also worked with well-known
                brands such as HCL, HP, Aditya Birla Group, Mahindra,
                MakeMyTrip, and many more. Some of our virtual events that have
                been noticed and appreciated globally include TATA Steel Virtual
                Meet, Donaldson India Annual Meet, AWS Partner Summit, and more
                other noteworthy events. We assure to provide our clients with
                some of the best virtual and hybrid platform to host their
                events. Our goal is to create a seamless digital experience for
                our audiences where they can enjoy a life-like experience of an
                event, a conference, or a meet, while being in the comfort of
                their own homes.
              </p>
            </div>
            <div
              className="container-fluid"
              id="counter-header"
              data-aos="fade-up"
            >
              <div className="row row-cols-1 row-cols-lg-2 mx-0 ">
                <div className="col my-auto order-1 order-lg-0">
                  <h5 className="heading-blue text-center text-lg-start">
                    What do we stand for?
                  </h5>
                  <p className="paragraph-justify">
                    We pride ourselves in handpicking some of the most talented
                    youth in the industry to work with us in our team. Their
                    values and work ethics to help us to create a respectable
                    work environment where we can work in accordance with the
                    needs and demands of our global clients. We create engaging
                    and captivating virtual events and spaces with immersive
                    illustration using advanced tools and technology. Our
                    virtual spaces help our clients connect efficiently with
                    their global audiences. By creating an exact replica of any
                    physical space or event, we enable virtual attendees to
                    enjoy a life-like experience and walkthrough of any physical
                    event. At the end of the day, we are not only an Event
                    Management Company that offers 3D and 360-degree virtual
                    events but also an incredible team that can provide
                    strategic and inspiring ideas to our clients.
                  </p>
                </div>
                <div className="col text-center">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/meeting-1.gif"
                    alt="..."
                    className="img-fluid p-3"
                  />
                </div>
              </div>
            </div>
          </section>
          {/* <!-- Global Hybrid & Virtual Event --> */}
          <section className="position-relative" data-aos="fade-up">
            <div className="track-record-circle"></div>
            <h4 className="text-center heading-blue mt-5 ">
              We are a Global Hybrid & Virtual Event Platform <br />
              with a Stellar Track Record
            </h4>
            <div className="container-fluid px-0 my-5 ">
              <div className="row row-cols-1 row-cols-md-4 row-cols-lg-4 d-none d-md-flex row mx-0 text-white">
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/track-number.png"
                    alt="..."
                    className="img-fluid w-100"
                  />
                  <div className="lh-sm position-absolute top-50 start-50 translate-middle ">
                    <h5 className="my-0">
                      <span className="fs-5 fw-bold counter" data-target="40">
                        <CountUp end={40} enableScrollSpy={true} />
                      </span>
                      M+
                    </h5>
                    <p className="my-0 fw-bold text-white">Attendees</p>
                  </div>
                </div>
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/track-number.png"
                    alt="..."
                    className="img-fluid w-100"
                  />
                  <div className="lh-sm position-absolute top-50 start-50 translate-middle">
                    <h5 className="my-0">
                      <span className="fw-bold counter" data-target="60">
                        <CountUp end={60} enableScrollSpy={true} />
                      </span>
                      +
                    </h5>
                    <p className="my-0 fw-bold text-white">Industries</p>
                  </div>
                </div>
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/track-number.png"
                    alt="..."
                    className="img-fluid w-100"
                  />
                  <div className="lh-sm position-absolute top-50 start-50 translate-middle">
                    <h5 className="my-0">
                      <span className=" fw-bold counter" data-target="35">
                        <CountUp end={35} enableScrollSpy={true} />
                      </span>
                      +
                    </h5>
                    <p className="my-0 fw-bold text-white">Countries</p>
                  </div>
                </div>
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/track-number.png"
                    alt="..."
                    className="img-fluid w-100"
                  />
                  <div className="lh-sm position-absolute top-50 start-50 translate-middle">
                    <h5 className="my-0">
                      <span className=" fw-bold counter" data-target="40">
                        <CountUp end={40} enableScrollSpy={true} />
                      </span>
                      K+
                    </h5>
                    <p className="my-0 fw-bold text-white">Exibitors</p>
                  </div>
                </div>
              </div>
              <div className="row row-cols-4 row-cols-md-4 row-cols-lg-4 d-flex d-md-none row mx-0 text-white">
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Counter.webp"
                    alt="..."
                    className="img-fluid "
                  />
                  <div
                    className="lh-sm position-absolute start-50 translate-middle"
                    style={{ top: "43%" }}
                  >
                    <h5 className="my-0 heading-blue lh-0">
                      <span className="fw-bold counter" data-target="40">
                        <CountUp end={40} enableScrollSpy={true} />
                      </span>
                      M+
                    </h5>
                    <p className="my-0 fw-bold lh-0 text-white">
                      <small>Attendees</small>
                    </p>
                  </div>
                </div>
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Counter.webp"
                    alt="..."
                    className="img-fluid "
                  />
                  <div
                    className="lh-sm position-absolute start-50 translate-middle"
                    style={{ top: "43%" }}
                  >
                    <h5 className="my-0 heading-blue lh-0">
                      <span className="fw-bold counter" data-target="60">
                        <CountUp end={60} enableScrollSpy={true} />
                      </span>
                      +
                    </h5>
                    <p className="my-0 fw-bold text-white">
                      <small>Industries</small>
                    </p>
                  </div>
                </div>
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Counter.webp"
                    alt="..."
                    className="img-fluid"
                  />
                  <div
                    className="lh-sm position-absolute start-50 translate-middle"
                    style={{ top: "43%" }}
                  >
                    <h5 className="my-0 heading-blue lh-0">
                      <span className=" fw-bold counter" data-target="35">
                        <CountUp end={35} enableScrollSpy={true} />
                      </span>
                      +
                    </h5>
                    <p className="my-0 fw-bold text-white">
                      <small>Countries</small>
                    </p>
                  </div>
                </div>
                <div className="col position-relative text-center px-0">
                  <img
                    src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/Counter.webp"
                    alt="..."
                    className="img-fluid"
                  />
                  <div
                    className="lh-sm position-absolute start-50 translate-middle"
                    style={{ top: "43%" }}
                  >
                    <h5 className="my-0 heading-blue lh-0">
                      <span className=" fw-bold counter" data-target="40">
                        <CountUp end={40} enableScrollSpy={true} />
                      </span>
                      K+
                    </h5>
                    <p className="my-0 fw-bold text-white">
                      <small>Exibitors</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* <!-- vision & mission --> */}
          <div className="container-fluid d-none d-lg-block" data-aos="fade-up">
            <div className="container-fluid position-relative px-0 ">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/tag-vission.png"
                alt="..."
                className="img-fluid w-100 "
              />
              <div
                className="container position-absolute top-50 translate-middle w-75"
                style={{ left: "58%" }}
              >
                <p>
                  As our unique name suggests, BizConnect has one major goal -
                  to connect businesses to their global customers. Our aim is to
                  help you save time and money while hosting your events, and we
                  do this by intricately designing and developing enthralling
                  virtual platforms. Furthermore, we make it a point to be
                  environmentally conscious and save the planet by reducing
                  carbon footprint.
                </p>
              </div>
            </div>
            <div className="container-fluid position-relative px-0">
              <img
                src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/About-page/tag-mission.png"
                alt="..."
                className="img-fluid w-100 "
              />
              <div
                className="container position-absolute top-50 translate-middle w-75"
                style={{ left: "45%" }}
              >
                <p>
                  Our mission is to create a riveting virtual space and platform
                  where our clients can conveniently connect with their global
                  audiences with a few simple clicks. Furthermore, we strive to
                  integrate advanced tools and features on our platform, all the
                  while keeping the process user-friendly for everyone. We would
                  love to showcase our full potential in AI-based Match Making
                  features and 3D and 360-degree panoramic views of any
                  environment. Finally, we want to help our clients generate
                  more revenue by devising a strategic, planned solution to all
                  their event management needs.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="container-fluid d-block d-lg-none" data-aos="fade-up">
            <div className="container-fluid shadow-sm rounded p-3 my-3">
              <h4 className="text-center heading-orange">Vision</h4>
              <p>
                As our unique name suggests, BizConnect has one major goal - to
                connect businesses to their global customers. Our aim is to help
                you save time and money while hosting your events, and we do
                this by intricately designing and developing enthralling virtual
                platforms. Furthermore, we make it a point to be environmentally
                conscious and save the planet by reducing carbon footprint.
              </p>
            </div>
            <div className="container-fluid shadow-sm rounded p-3 my-3">
              <h4 className="text-center heading-blue">Mission</h4>
              <p>
                Our mission is to create a riveting virtual space and platform
                where our clients can conveniently connect with their global
                audiences with a few simple clicks. Furthermore, we strive to
                integrate advanced tools and features on our platform, all the
                while keeping the process user-friendly for everyone. We would
                love to showcase our full potential in AI-based Match Making
                features and 3D and 360-degree panoramic views of any
                environment. Finally, we want to help our clients generate more
                revenue by devising a strategic, planned solution to all their
                event management needs.{" "}
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

//   <link href="https://unpkg.com/aos@2.3.1/dist/aos.css" rel="stylesheet">
