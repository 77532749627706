import * as React from "react";
import HeaderComponent from "../component/Header/App";
import WorkSection from "../component/Sections/WorkSection";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, Fab } from "@mui/material";

const socialLinks = [
  {
    icon: <FacebookIcon sx={{ fontSize: "20px" }} />,
    link: "https://www.facebook.com/bizconnectevents.india",
  },
  {
    icon: <LinkedInIcon sx={{ fontSize: "20px" }} />,
    link: "https://www.linkedin.com/company/bizconnect-pvt-ltd/mycompany/",
  },
  {
    icon: <InstagramIcon sx={{ fontSize: "20px" }} />,
    link: "https://www.instagram.com/bizconnect_global_events/",
  },
  {
    icon: <TwitterIcon sx={{ fontSize: "20px" }} />,
    link: "https://twitter.com/BizConnectEven1",
  },
  // {
  //   icon: <YouTubeIcon sx={{ fontSize: "19px" }} />,
  //   link: "https://www.youtube.com/channel/UCGj27x3_ShNbpu97zMDiYlQ",
  // },
];

export default function App() {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
          position: "fixed",
          right: 3,
          bottom: 10,
          zIndex: "20 !important",
        }}
      >
        {socialLinks?.map(({ icon, link }, index) => {
          return (
            <Fab
              key={index}
              size="small"
              component="a"
              variant="link"
              target="_blank"
              href={link}
              sx={{
                zIndex: 999,
                backgroundColor: "#fff !important",
                "&:hover": {
                  color: "#0b4995 !important",
                },
              }}
            >
              {icon}
            </Fab>
          );
        })}
      </Box>
      <HeaderComponent />
      <WorkSection />
    </>
  );
}
