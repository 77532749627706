import * as React from "react";
import { Card, CardMedia, CardContent, Typography } from "@mui/material";

export default function App({ src, title, desc }) {
  return (
    <>
      <Card className="d-flex flex-column shadow-none pb-2 w-100">
        <CardMedia
          component="img"
          height="194"
          sx={{
            objectFit: "cover",
            aspectRatio: "16/9",
            borderRadius: "12px",
          }}
          image={src}
          alt={title}
          className="p-2"
        />
        <CardContent className="d-flex flex-column h-100 mt-auto multiLineLabel">
          <Typography
            className="mb-auto lh-sm textHeadMaxLine"
            variant="h6"
            sx={{ minHeight: 50 }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            className="textMaxLine text-start mt-2"
          >
            {desc}
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
