import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
// import required modules
import { Pagination, Autoplay } from "swiper";

import "./LeadCapture.css";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useNavigate } from "react-router-dom";

const Data = [
  {
    id: 1,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-1.png",
    name: "Planning before the event",
    desc: "An effective event lead capture strategy begins before you ever set foot in the venue. Start with the type of event. Do some research and learn about where the industry is moving. Look for:a.Trends b. Potential obstacles c. Disruptors d.Common problems.  When you demonstrate industry knowledge to potential leads, you’re more likely to make a meaningful connection that leads to next steps in the sales process. know your audience. This helps you be deliberate — both in choosing the events you attend, and how you approach the events. ",
  },
  {
    id: 2,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-2.png",
    name: "Execution during the event",
    desc: "What’s more important than getting leads at events? Getting the right leads at events. At the event, aim to have meaningful conversations with each person you meet.  Ditch the generic intros and ask specific questions. Deliberate intros improve your chances of moving the conversation forward If there’s a fit, don’t go straight for the business card. Instead, ask for their email address and work to set up a more personal call a few days after the event. An event is a great place to have a closing conversation. The prospective customer is excited and thinking about new ideas, so it’s a perfect time to capture their energy and engagement and get them to sign up with you.",
  },
  {
    id: 3,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-3.png",
    name: "Follow-up after the event",
    desc: "Your follow-up strategy should include making your follow-up calls when you said you would...use the event you both attended as an ice-breaker to make a quick personal connection. Post connecting, bring up any specific details from your conversations at the event to cement the connection with your leads Follow-up should also include measuring success for your business. Do use segmentation to tag the contacts you captured at the event and track them through the sales process.  The more information you gather on the leads from an event, the easier it is to measure success.",
  },
  {
    id: 4,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-4.jpg",
    name: "Identify Who’s Going to the Event",
    desc: "Catch the right fish in your net. Cast a wide net, usually with giveaways, prizes, and gift cards, because the next customer could be anyone. Look for company names and target contact names or roles, if possible.",
  },
  {
    id: 5,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-5.png",
    name: "Prioritize Pre-Event Outreach",
    desc: "Go by industry, company size or revenue and geographic location. Once you know who’s going to the event, you can prioritize prospects.  This will be  helpful for both your sales and marketing teams.",
  },
  {
    id: 6,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-6.png",
    name: "Launch a Multi-Touch Campaign",
    desc: "You can use paid social ads, direct email , phone calls and sometimes even direct mail to make sure. But by the time the event rolls around, your prospect knows who you are – and has you on their schedule at the show!",
  },
  {
    id: 7,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-7.png",
    name: "Motivate Attendees to Book a Meeting",
    desc: "Your event strategy may change whether you’re walking the floor or have a booth, but the goal is the same: To book a meeting or demo.",
  },
  {
    id: 8,
    src: "https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/slide-8.png",
    name: "Enrich Your Post-Show Leads",
    desc: "Clean and enrich the lead data after the show: industry, company size, department budget, tech stack, and any planned projects are all great data points, if you can find them, to ensure your prospect is a good fit, and you’re not wasting your time.",
  },
];

export default function LeadCapture() {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    const newUrl = window.location.href; // Replace with your desired URL
    window.sessionStorage.setItem("storedUrl", newUrl);
    navigate("/contactus");
  };

  return (
    <>
      <div id="leadCapture">
        <header className="mt-md-5">
          <div className="container rounded-3 overflow-hidden py-5 ">
            <div className="row justify-content-center">
              <div className="col-12 col-md-6 order-md-second d-flex">
                <img
                  src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/phone-ui.png"
                  alt=".."
                  className="mx-auto img-fluid"
                />
              </div>
              <div className="col-12 col-md-6 order-md-first d-flex flex-column">
                <div className="content text-break m-auto p-md-5">
                  <p
                    className="fw-bold text-white mb-2 text-head ms-4 text-start"
                    style={{ fontSize: "3.5rem" }}
                  >
                    Lead Capture
                  </p>
                  <p
                    className="ms-md-4 mb-4 text-center text-md-start"
                    style={{ color: "#ffffffb3" }}
                  >
                    Trade shows, Seminars and conferences are full of potential
                    leads (if you know how and where to look). To capture the
                    leads,one needs a plan.Let us see how to capture leads at
                    events and convert those leads into sales.
                    {/* <Typography
                    variant="subtitle2"
                    component="p"
                    sx={{ color: "#ffffffb3", ml: 3, mb: 2 }}
                  >
                    BizConnect LeadCapture App gives you the convenience of
                    capturing leads from all types of QRCode & Barcodes from the
                    delegate's badges on your mobile phone. Collecting business
                    cards and recalling the conversations isnow history. Scan,
                    make notes, reconnect and grow your business ALL IN A JIFFY
                    !!
                  </Typography> */}
                  </p>
                  <div className="btns d-flex justify-content-md-start justify-content-center align-items-center gap-3 ms-md-4 mb-4">
                    <a
                      className="text-dark text-decoration-none"
                      target="_blank"
                      rel="noreferrer"
                      href="https://play.google.com/store/apps/details?id=com.leadCapture.app&hl=en&gl=US"
                    >
                      <button className="btn bg-white d-flex justify-content-start align-items-center fs-6">
                        <i
                          className="fa-brands fa-google-play me-2"
                          style={{ color: "#000" }}
                        ></i>
                        Android
                      </button>
                    </a>
                    <Tooltip title="coming soon..">
                      <button className="btn bg-white d-flex justify-content-start align-items-center fs-6">
                        <img
                          src="https://vho.bizconnectevents.com/Bizconnect-Events-Images/Lead+Capture/Vector.png"
                          alt=".."
                          style={{ width: "18px", height: "18px" }}
                          className="me-2"
                        />
                        {"IOS"}
                      </button>
                    </Tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* main */}
        <main className="mt-5 pt-5 pb-md-5">
          <div className="container">
            <div className="row justify-content-center gy-3">
              <div className="col-12 col-lg-6">
                <img
                  src="https://images.unsplash.com/photo-1564069114553-7215e1ff1890?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1332&q=80"
                  className="img-fluid rounded-3"
                  alt=""
                />
              </div>
              <div className="col-12 col-lg-6">
                <div className="rounded-3 h-100  d-flex flex-column justify-content-center align-items-md-start justify-content-center gap-2 ms-md-5">
                  <h2 className="fw-bold text-md-start text-center mt-4 mt-md-0">
                    The event lead capture process has 3 steps:
                  </h2>
                  <div>
                    <div className="d-flex justify-content-center align-items-center">
                      <ul className="p-0 d-flex flex-column justify-content-start align-items-start gap-2">
                        <li className="fs-6" style={{ listStyle: "none" }}>
                          1. Planning before the event
                        </li>
                        <li className="fs-6" style={{ listStyle: "none" }}>
                          2. Execution during the event
                        </li>
                        <li className="fs-6" style={{ listStyle: "none" }}>
                          3. Follow-up after the event
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span className="fw-bold text-body-secondary text-md-start text-center">
                    Now let us see how to go about it
                  </span>
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* carousel */}
        <div className="container">
          <Swiper
            id="mySwiperLeadCapture"
            slidesPerView={3}
            spaceBetween={30}
            loop={true}
            centeredSlides={true}
            loopFillGroupWithBlank={true}
            pagination={{
              clickable: true,
            }}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Pagination, Autoplay]}
            className="#mySwiperLeadCapture mySwiper mb-5 py-5"
            breakpoints={{
              300: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 5,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
          >
            {Data?.map(({ src, name, desc }, index) => {
              return (
                <SwiperSlide key={index} className="h-100">
                  <Card className="d-flex flex-column shadow-none pb-2 w-100">
                    <CardMedia
                      component="img"
                      height="194"
                      sx={{
                        objectFit: "contain",
                        borderRadius: "12px",
                      }}
                      image={src}
                      alt={name}
                      className="p-2"
                    />
                    <CardContent className="d-flex flex-column h-100 mt-auto multiLineLabel">
                      <Typography
                        className="textHeadMaxLine"
                        variant="h6"
                        sx={{ minHeight: 50 }}
                      >
                        {name}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        className="textMaxLine text-start mt-2"
                      >
                        {desc}
                      </Typography>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>

        <Box className="my-5 d-flex justify-content-center flex-column align-items-center gap-3">
          <Typography variant="h6" component="h6" className="fw-bold">
            For more additional details relating to Lead Capture
          </Typography>
          <Button
            size="medium"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            sx={{ bgcolor: "#E37F6A !important" }}
            onClick={handleButtonClick}
          >
            Contact Us
          </Button>
        </Box>
      </div>
    </>
  );
}
